import React, { useContext } from "react";
import heroImage from "shared/images/4_Start_Process.webp";
import { Button } from "react-bootstrap";
import "./StarterPage.scss";
import { booleanContext } from "App";
import { languages } from "shared/languages/languages";

function StarterPage(props) {
  const { introText, digitalText } = props;
  const selectedlang = useContext(booleanContext);
  const getText = (attr) => {
    if (selectedlang) {
      return languages[selectedlang].starterPage[attr];
    }
    return languages.en.starterPage[attr];
  };

  return (
    <div>
      <div className="hero-banner">
        <img src={heroImage} alt="banner-img" className="hero-img" />
      </div>
      <div className="cal-page-title">{getText("pageTitle")}</div>
      <div className="starterIntroText">{introText}</div>
      <div className="validateButtons my-5 py-5">
        {digitalText ? (
          <Button
            href={
              selectedlang ? `/${selectedlang}/submitrequest` : `/submitrequest`
            }
            name="californiaCitizen"
            className="submit-btn mr-4"
          >
            {getText("californiaResident")}
          </Button>
        ) : (
          <Button
            href={
              selectedlang ? `/${selectedlang}/optoutrequest` : `/optoutrequest`
            }
            name="californiaCitizen"
            className="submit-btn mr-4"
          >
            {getText("californiaResident")}
          </Button>
        )}

        <Button
          href={
            selectedlang
              ? `/${selectedlang}/noncaliforniaresident`
              : `/noncaliforniaresident`
          }
          name="californiaNonCitizen"
          className="cancel-btn ml-5"
        >
          {getText("nonCaliforniaResident")}
        </Button>
      </div>
      <div className="startercontent ">
        <p className="starterintro mb-3">{getText("intro")}</p>
        <p className="starterintro mb-2">
          <strong>(a) &nbsp; {getText("header1")}</strong>{" "}
          {getText("description1")}
        </p>
        {digitalText ? (
          <p className="starterintro">
            <strong>(b) &nbsp; {getText("header2")}</strong>{" "}
            {getText("description2")}
          </p>
        ) : (
          ""
        )}
        <p className="starterintro mt-5">
          {getText("description3")}{" "}
          <a
            href="https://www.aflac.com/about-aflac/privacy-policy.aspx"
            data-toggle="tooltip"
            data-placement="bottom"
            target="_blank"
            rel="noreferrer noopener"
            title="https://www.aflac.com/about-aflac/privacy-policy.aspx"
            className="aflac-links"
          >
            {getText("privacyPolicy")}
          </a>
          .
        </p>
      </div>

      {/* Bottom Notification Bar */}

      <div className="notificationbar">
        <div className="context">
          <h4 className="text-white context-heading">
            {getText("requestByCall")}
          </h4>
          <h4 className="text-white context-heading mt-4">
            {getText("requestByMail")}
          </h4>
        </div>
        <div className="downloadButton pt-3">
          <Button
            name="downloadInstructions"
            className="download-btn"
            href={
              selectedlang ? `/${selectedlang}/instructions` : `/instructions`
            }
          >
            {getText("downloadinstr")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default StarterPage;
