import React, { useContext } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import b_Our_Privacy_Policy from "shared/images/2b_Our_Privacy_Policy.webp";
import Your_Choice from "shared/images/2c_Your_Choice.webp";
import Fraud_Security from "shared/images/2d_Fraud_Security.webp";
import "./PrivacyCenterCard.css";
import { booleanContext } from "App";

function PrivacyCenterCard(props) {
  const { getText } = props;
  const selectedlang = useContext(booleanContext);

  return (
    <>
      <Row xl="3" lg="3" md="3" sm="1" className="privacy-center-card-grid">
        <Col>
          <Card className="privacy-center-card">
            <Card.Img
              aria-label="privacyPolicies"
              variant="top"
              src={b_Our_Privacy_Policy}
              className="press_card_img"
            />
            <Card.Body>
              <Card.Title className="privacy-center-card-title">
                {getText("privacyPolicies")}
              </Card.Title>
              <Card.Text className="privacy-center-card-text">
                {getText("privacyPoliciesDesc")}
              </Card.Text>
              <Card.Link
                href="https://www.aflac.com/about-aflac/privacy-policy.aspx"
                className="privacy-center-card-link text-center"
                target="_blank"
                rel="noreferrer noopener"
              >
                {getText("seeMore")} <FontAwesomeIcon icon={faChevronRight} />{" "}
              </Card.Link>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="privacy-center-card">
            <Card.Img
              aria-label="choices"
              variant="top"
              src={Your_Choice}
              className="press_card_img"
            />
            <Card.Body>
              <Card.Title className="privacy-center-card-title">
                {getText("choices")}
              </Card.Title>
              <Card.Text className="privacy-center-card-text">
                {getText("choicesDesc")}
              </Card.Text>
              <Card.Link
                href={
                  selectedlang
                    ? `/${selectedlang}/exerciserights`
                    : `/exerciserights`
                }
                className="privacy-center-card-link"
              >
                {getText("takeControl")}{" "}
                <FontAwesomeIcon icon={faChevronRight} />{" "}
              </Card.Link>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="privacy-center-card">
            <Card.Img
              aria-label="fraudSecurity"
              variant="top"
              src={Fraud_Security}
              className="press_card_img"
            />
            <Card.Body>
              <Card.Title className="privacy-center-card-title">
                {getText("fraudSecurity")}
              </Card.Title>
              <Card.Text className="privacy-center-card-text">
                {getText("fraudSecurityDesc")}
              </Card.Text>
              <Card.Link
                href={
                  selectedlang
                    ? `/${selectedlang}/fraudandsecurity`
                    : `/fraudandsecurity`
                }
                className="privacy-center-card-link"
              >
                {getText("seeMore")} <FontAwesomeIcon icon={faChevronRight} />{" "}
              </Card.Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default PrivacyCenterCard;
