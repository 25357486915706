import React, { useContext } from "react";
import "./HeroBanner.css";
import { booleanContext } from "App";

function HeroBanner(props) {
  const selectedlang = useContext(booleanContext);
  return (
    <div className="shared-banner">
      <h1
        className={
          selectedlang === "es" ? "shared-hero-text-ES" : "shared-hero-text"
        }
      >
        {" "}
        {props.title}{" "}
      </h1>
      <img src={props.img} alt="banner-img" className="shared-hero-img" />
    </div>
  );
}

export default HeroBanner;
