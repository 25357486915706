import React from "react";

function SmishingDetails(props) {
  const { getText } = props;
  return (
    <div className="fs-second-details-item">
      <p>{getText("smishingIntro")}</p>
      <p>{getText("themesText")}: </p>
      <ul className="fs-details-item-innerlist">
        <li className="fs-details-item-innerlist-item">
          {getText("smishingText1")}
        </li>
        <li className="fs-details-item-innerlist-item">
          {getText("smishingText2")}{" "}
        </li>
      </ul>
      <br />
      <p>{getText("protectYourself")}: </p>
      <ul>
        <li className="fs-details-item-innerlist-item">
          {getText("smishingText3")}
        </li>
        <li className="fs-details-item-innerlist-item">
          {getText("smishingText4")}
        </li>
        <li className="fs-details-item-innerlist-item">
          {getText("smishingText5")}
        </li>
        <li className="fs-details-item-innerlist-item">
          {getText("smishingText6")}
        </li>
      </ul>
    </div>
  );
}

export default SmishingDetails;
