import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReCaptcha from "shared/components/ReCaptcha";
import {
  textValidation,
  emailValidation,
  zipcodeValidation,
  textValidationES,
  emailValidationES,
  zipcodeValidationES,
} from "../SubmitRequest/Validation";
import { getService } from "shared/library/restApi";
import getPayload from "shared/library/getPayload";

function OptOutForm(props) {
  const {
    getSubmitRequestId,
    getAcknowledgement,
    configValues,
    enableLoader,
    getText,
    selectedlang,
  } = props;

  const [sendRequest, setSendRequest] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(true);

  const handleReCaptchaClick = (value) => {
    setDisableSubmit(!value);
  };

  const { register, handleSubmit, formState, errors } = useForm({
    mode: "onChange",
  });

  const _onFocus = (e) => {
    e.currentTarget.type = "date";
  };

  const _onBlur = (e) => {
    e.currentTarget.type = "text";
    e.currentTarget.placeholder = "Date Of Birth *";
  };

  const onSubmit = (data) => {
    setSendRequest(data);
  };

  useEffect(() => {
    if (sendRequest) {
      // activate the loader
      enableLoader(true);
      const inputsForPayload = {
        request: "optOut",
        inputsData: sendRequest,
        configValues,
        selectedlang,
      };
      const payload = getPayload(inputsForPayload);

      //API request

      const submitOptions = {
        method: `POST`,
        url: `${process.env.REACT_APP_WIREWHEEL_URL}`,
        data: payload,
      };

      const submitResponse = getService(submitOptions);
      submitResponse
        .then((response) => {
          if (response.status === 201 && response.data) {
            // get the root id after successful request submit
            getSubmitRequestId(response.data.rootId);
          }
        })
        .then(() => {
          // deactivate the loader and enable the Acknowledgement
          enableLoader(false);
          getAcknowledgement(true);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    return () => {
      setSendRequest("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendRequest]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <br />
        <Form.Group>
          <Form.Label className="radio-label font-weight-bold ">
            {getText("email")}
          </Form.Label>
          <Form.Row className="w-75 ml-3">
            <Col lg={6} md={6} sm={6} xs={12}>
              <Form.Control
                name="email"
                type="email"
                placeholder={getText("emailPlaceholder")}
                className={
                  !errors.email
                    ? "form-input-field mb-2"
                    : "form-input-field error-border mb-2"
                }
                ref={
                  selectedlang
                    ? register(emailValidationES)
                    : register(emailValidation)
                }
              />
              {errors.email && (
                <small className="text-danger">{errors.email.message}</small>
              )}
            </Col>
            <Col lg={6} md={6} sm={6} xs={12} className="marginleft-4"></Col>
          </Form.Row>
          <strong className="required-field-label marginleft-4">
            {getText("emailText")}
          </strong>
        </Form.Group>

        <Form.Label className="radio-label font-weight-bold mb-3">
          {getText("enterpriInsuredInfo")}
        </Form.Label>

        <Form.Group>
          <Form.Row className="w-75 ml-3">
            <Col className="marginright-4">
              <Form.Control
                name="primaryFirstName"
                placeholder={getText("firstName")}
                className={
                  !errors.primaryFirstName
                    ? "form-input-field"
                    : "form-input-field error-border"
                }
                ref={
                  selectedlang
                    ? register(textValidationES)
                    : register(textValidation)
                }
              />

              {errors.primaryFirstName && (
                <small className="text-danger">
                  {" "}
                  {errors.primaryFirstName.message}{" "}
                </small>
              )}
            </Col>

            <Col className="marginleft-4">
              <Form.Control
                name="primaryLastName"
                placeholder={getText("lastName")}
                className={
                  !errors.primaryLastName
                    ? "form-input-field"
                    : "form-input-field error-border"
                }
                // className={classNames("form-control", { "is-invalid": true })}
                ref={
                  selectedlang
                    ? register(textValidationES)
                    : register(textValidation)
                }
              />
              {errors.primaryLastName && (
                <small className="text-danger">
                  {errors.primaryLastName.message}
                </small>
              )}
            </Col>
          </Form.Row>
        </Form.Group>

        <Form.Group>
          <Form.Row className="w-75  ml-3 ">
            <Col className="marginright-4">
              <Form.Control
                name="address1"
                placeholder={getText("address1")}
                className={
                  !errors.address1
                    ? "form-input-field"
                    : "form-input-field error-border"
                }
                ref={register({ required: true })}
              />

              {errors.address1 && errors.address1.type === "required" && (
                <small className="text-danger">
                  {selectedlang
                    ? `Este campo es obligatorio`
                    : `This field is required`}
                </small>
              )}
            </Col>
            <Col className="marginleft-4">
              <Form.Control
                name="address2"
                placeholder={getText("address2")}
                className="form-input-field"
                ref={register({ required: false })}
              />
            </Col>
          </Form.Row>
        </Form.Group>

        <Form.Group>
          <Form.Row className="w-75 ml-3">
            <Col className="marginright-4">
              <Form.Control
                name="city"
                placeholder={getText("city")}
                className={
                  !errors.city
                    ? "form-input-field"
                    : "form-input-field error-border"
                }
                ref={
                  selectedlang
                    ? register(textValidationES)
                    : register(textValidation)
                }
              />

              {errors.city && (
                <small className="text-danger"> {errors.city.message} </small>
              )}
            </Col>
            <Col className="marginleft-4">
              <Form.Control
                name="state"
                placeholder={getText("state")}
                className={
                  !errors.state
                    ? "form-input-field"
                    : "form-input-field error-border"
                }
                ref={
                  selectedlang
                    ? register(textValidationES)
                    : register(textValidation)
                }
              />
              {errors.state && (
                <small className="text-danger"> {errors.state.message}</small>
              )}
            </Col>
          </Form.Row>
        </Form.Group>

        <Form.Group>
          <Form.Row className="w-75  ml-3">
            <Col className="marginright-4">
              <Form.Control
                name="postalzipcode"
                placeholder={getText("zipCode")}
                className={
                  !errors.postalzipcode
                    ? "form-input-field"
                    : "form-input-field error-border"
                }
                ref={
                  selectedlang
                    ? register(zipcodeValidationES)
                    : register(zipcodeValidation)
                }
              />

              {errors.postalzipcode && (
                <small className="text-danger">
                  {" "}
                  {errors.postalzipcode.message}
                </small>
              )}
            </Col>
            <Col className="marginleft-4">
              <Form.Control
                name="country"
                placeholder={getText("country")}
                className={
                  !errors.country
                    ? "form-input-field"
                    : "form-input-field error-border"
                }
                ref={
                  selectedlang
                    ? register(textValidationES)
                    : register(textValidation)
                }
              />

              {errors.country && (
                <small className="text-danger"> {errors.country.message}</small>
              )}
            </Col>
          </Form.Row>
        </Form.Group>

        <Form.Group>
          <Form.Row className="w-75  ml-3">
            <Col className="marginright-4">
              <Form.Control
                type="text"
                name="dob"
                placeholder={getText("dob")}
                onFocus={_onFocus}
                onBlur={_onBlur}
                className={
                  !errors.dob
                    ? "form-input-field"
                    : "form-input-field error-border"
                }
                ref={register({ required: "true" })}
              />
              {errors.dob && (
                <small className="text-danger">
                  {selectedlang
                    ? `Este campo es obligatorio`
                    : `This field is required`}
                </small>
              )}
            </Col>
            <Col className="marginleft-4"></Col>
          </Form.Row>
        </Form.Group>

        <strong className="required-field-label marginleft-4">
          {getText("required")}
        </strong>

        <ReCaptcha
          reCaptchaClick={handleReCaptchaClick}
          selectedlang={selectedlang}
        />

        <br />
        <br />
        <Form.Group>
          <Row className="w-75 ml-3 justify-content-center">
            <Col lg={6} md={6} sm={6} xs={6}>
              <Button
                className="form-btn submit-btn marginright-4"
                type="submit"
                disabled={!formState.isValid || disableSubmit}
              >
                {getText("submitBtn")}
              </Button>
            </Col>

            <Col lg={6} md={6} sm={6} xs={6}>
              <Button
                className="form-btn cancel-btn marginleft-4"
                href={
                  selectedlang
                    ? `/${selectedlang}/exerciserights`
                    : `/exerciserights`
                }
              >
                {getText("cancelBtn")}
              </Button>
            </Col>
          </Row>
        </Form.Group>
      </form>
    </>
  );
}

export default OptOutForm;
