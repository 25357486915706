import React from "react";

function IEBrowswerNotSupportedMessage() {
  const text =
    "You are not using a supported browser. For the best experience, we recommend using";
  return (
    <div className="ie-unsupported-msg">
      <small className="text-danger">
        {text}
        <a
          href="https://www.google.com/chrome/?brand=VDKB&ds_kid=43700053271226624&utm_source=bing&utm_medium=cpc&utm_campaign=1008138%20%7C%20Chrome%20Win10%20%7C%20DR%20%7C%20ESS01%20%7C%20APAC%20%7C%20IN%20%7C%20en%20%7C%20Desk%20%7C%20Bing%20SEM%20%7C%20BKWS%20~%20Top%20KWDS%20-%20Exact%20-%20NEW&utm_term=google%20chrome&utm_content=Desk%20%7C%20BING%20SEM%20%7C%20BKWS%20%7C%20Exact%20~%20Google%20Chrome%20~%20Top%20KWDS&gclid=8e5bd0c92d14195415578f13d54657fc&gclsrc=3p.ds"
          target="_blank"
          rel="noreferrer"
          className="mr-1 ml-1"
        >
          Google Chrome
        </a>
        or
        <a
          href="https://www.microsoft.com/en-us/edge"
          target="_blank"
          rel="noreferrer"
          className="ml-1"
        >
          Microsoft Edge
        </a>
        .
      </small>
    </div>
  );
}

export default IEBrowswerNotSupportedMessage;
