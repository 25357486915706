import React, { useState, useContext } from "react";
import { Nav, Navbar, NavLink } from "react-bootstrap";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import YourRightsChoice from "./YourRightsChoice";
import OurPrivacyPolicies from "./OurPrivacyPolicies";
import NavLogo from "shared/images/bc_standard_logo.png";
import { languages } from "shared/languages/languages";
import { booleanContext } from "App";

import "./HeaderNavbar.css";
import ContrastToggler from "components/ContrastToggler/ContrastToggler";

const initialState = {
  hovered0: false,
  hovered1: false,
  hovered2: false,
  hovered3: false,
};

function HeaderNavbar() {
  const selectedlang = useContext(booleanContext);
  const pathArr = window.location.pathname.split("/").filter((e) => e);
  const pathname =
    selectedlang === "es"
      ? pathArr.length === 2
        ? pathArr[1]
        : ""
      : pathArr.length === 1
      ? pathArr[0]
      : "";

  const getText = (attr) => {
    if (selectedlang) {
      return languages[selectedlang].header[attr];
    }
    return languages.en.header[attr];
  };
  const [hovered, setHovered] = useState(initialState);
  const [isRightsVisible, setIsRightsVisible] = useState(false);
  const [isPoliciesVisible, setIsPoliciesVisible] = useState(false);

  const rightsChevIconHandle = (e) => {
    e.preventDefault();
    setIsRightsVisible(!isRightsVisible);
  };

  const policiesChevIconHandle = (e) => {
    e.preventDefault();
    setIsPoliciesVisible(!isPoliciesVisible);
    if (isRightsVisible) {
      setIsRightsVisible(!isRightsVisible);
    }
  };

  return (
    <>
      <div className="toggle-bar">
        <ContrastToggler getText={getText} />
        <label className="separator">|</label>
        <a
          className="spanish-link"
          href={
            selectedlang === "es"
              ? window.location.origin + "/" + pathname
              : window.location.origin + "/es/" + pathname
          }
        >
          {selectedlang === "es" ? (
            <label>{"English"}</label>
          ) : (
            <label>{"Español"}</label>
          )}
        </a>
      </div>
      <Navbar className="mnav-item-list" expand="lg">
        <Navbar.Brand href="https://www.aflac.com/">
          <img alt="logo" src={NavLogo} className="nav-logo" />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto mnav">
            <span className="mnav-item">
              <NavLink
                href={selectedlang ? `/${selectedlang}` : `/`}
                onMouseEnter={() =>
                  setHovered({ ...initialState, hovered0: true })
                }
                onMouseLeave={() =>
                  setHovered({ ...initialState, hovered0: false })
                }
                className={
                  !hovered.hovered0 && Object.values(hovered).indexOf(true) > -1
                    ? "mnav-item-nohover"
                    : hovered.hovered0
                    ? "mnav-item-hover mnav-item-link"
                    : "mnav-item-link"
                }
              >
                {getText("privacyCenter")}
              </NavLink>
            </span>
            <span className="mnav-item">
              <NavLink
                href={
                  selectedlang
                    ? `/${selectedlang}/ourprivacypolicies`
                    : `/ourprivacypolicies`
                }
                onMouseEnter={() =>
                  setHovered({ ...initialState, hovered1: true })
                }
                onMouseLeave={() =>
                  setHovered({ ...initialState, hovered1: false })
                }
                className={
                  !hovered.hovered1 && Object.values(hovered).indexOf(true) > -1
                    ? "mnav-item-nohover"
                    : hovered.hovered1
                    ? "mnav-item-hover mnav-item-link"
                    : "mnav-item-link"
                }
              >
                {getText("policies")}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="chevron-down-icon"
                />
                {isPoliciesVisible ? (
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="chevronDownIcon"
                    onClick={policiesChevIconHandle}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="chevron-right-icon"
                    onClick={policiesChevIconHandle}
                  />
                )}
              </NavLink>
              {isPoliciesVisible ? (
                <OurPrivacyPolicies
                  content="dropdown-content"
                  onMouseEnter={() =>
                    setHovered({ ...initialState, hovered1: true })
                  }
                  onMouseLeave={() =>
                    setHovered({ ...initialState, hovered1: false })
                  }
                  getText={getText}
                />
              ) : null}
            </span>

            <span className="mnav-item">
              <NavLink
                onMouseEnter={() =>
                  setHovered({ ...initialState, hovered2: true })
                }
                onMouseLeave={() =>
                  setHovered({ ...initialState, hovered2: true })
                }
                className={
                  !hovered.hovered2 && Object.values(hovered).indexOf(true) > -1
                    ? "mnav-item-nohover"
                    : hovered.hovered2
                    ? "mnav-item-hover mnav-item-link"
                    : "mnav-item-link"
                }
              >
                {getText("rights")}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="chevron-down-icon"
                />
                {isRightsVisible ? (
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="chevronDownIcon"
                    onClick={rightsChevIconHandle}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="chevron-right-icon"
                    onClick={rightsChevIconHandle}
                  />
                )}
              </NavLink>
              {isRightsVisible ? (
                <YourRightsChoice
                  content="dropdown-content"
                  onMouseEnter={() =>
                    setHovered({ ...initialState, hovered2: true })
                  }
                  onMouseLeave={() =>
                    setHovered({ ...initialState, hovered2: false })
                  }
                  getText={getText}
                />
              ) : null}
            </span>
            <span className="mnav-item">
              <NavLink
                href={
                  selectedlang
                    ? `/${selectedlang}/fraudandsecurity`
                    : `/fraudandsecurity`
                }
                onMouseEnter={() =>
                  setHovered({ ...initialState, hovered3: true })
                }
                onMouseLeave={() =>
                  setHovered({ ...initialState, hovered3: false })
                }
                className={
                  !hovered.hovered3 && Object.values(hovered).indexOf(true) > -1
                    ? "mnav-item-nohover"
                    : hovered.hovered3
                    ? "mnav-item-hover mnav-item-link"
                    : "mnav-item-link"
                }
              >
                {getText("fraud")}
              </NavLink>
            </span>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {/* ============================================================= */}
      {hovered.hovered1 ? (
        <OurPrivacyPolicies
          content="sub-nav-blockZZ"
          onMouseEnter={() => setHovered({ ...initialState, hovered1: true })}
          onMouseLeave={() => setHovered({ ...initialState, hovered1: false })}
          getText={getText}
        />
      ) : null}
      {hovered.hovered2 ? (
        <YourRightsChoice
          content="sub-nav-blockZZ"
          onMouseEnter={() => setHovered({ ...initialState, hovered2: true })}
          onMouseLeave={() => setHovered({ ...initialState, hovered2: false })}
          getText={getText}
        />
      ) : null}
    </>
  );
}
export default HeaderNavbar;
