import React, { useContext } from "react";
import heroImage from "shared/images/3_CA_Privacy_Rights.webp";
import HeroBanner from "shared/HeroBanner/HeroBanner";
import submitrequestImage from "shared/images/3_Submit_Pers_Request.webp";
import optoutrequestImage from "shared/images/3_Do_Not_Sell.webp";
import { Image, Button, Container, Row, Col } from "react-bootstrap";
import { languages } from "shared/languages/languages";
import { booleanContext } from "App";
import "./ExerciseRights.scss";

function ExerciseRights() {
  const selectedlang = useContext(booleanContext);

  const getText = (attr) => {
    if (selectedlang) {
      return languages[selectedlang].rights[attr];
    }
    return languages.en.rights[attr];
  };
  return (
    <>
      <div className="ca-hero-img">
        <HeroBanner title={getText("bannerTitle")} img={heroImage} />
      </div>
      <div className="page-title">{getText("pageTitle")}</div>

      {/* <Container>
        <Row className="ml-2">
          <div>
            <div className="caintro pt-5">
              <h3 id="caheading">{getText("introTitle")}</h3>
              <p className="ccpaintro">{getText("introDesc")}</p>
              <ul className="ccpa-list ml-5">
                <li className="mb-2">{getText("listText1")}</li>
                <li className="mb-2">{getText("listText2")}</li>
                <li className="mb-2">{getText("listText3")}</li>
                <li className="mb-2">{getText("listText4")}</li>
              </ul>
            </div>
            <h1 className="eyour-rights-title text-center my-5 py-5">
              {getText("rightsTitle")}
            </h1>
          </div>
        </Row>
      </Container> */}

      {/* SUBMIT REQUEST SECTION */}

      <div className="submitrequestsection">
        <Container>
          <Row>
            <Col lg={6} md={6} sm={6} xs={12} className="pr-5">
              <div className="submitrequesttext mt-5">
                <h3 className="mb-3">{getText("submitRequestTitle")}</h3>
                <Button
                  href={
                    selectedlang
                      ? `/${selectedlang}/submitrequest`
                      : `/submitrequest`
                  }
                  name="submitRequest"
                  className="btn submit-request mb-5"
                >
                  {getText("submitRequestBtn")}
                </Button>
                <hr className="hrline"></hr>

                <p className="requestIntro">
                  {getText("agentRequestText")}{" "}
                  <a
                    href={
                      selectedlang
                        ? `/${selectedlang}/instructions`
                        : `/instructions`
                    }
                  >
                    {getText("here")}
                  </a>
                  {selectedlang === "es"
                    ? " las instrucciones de envío por correo postal en virtud de la CCPA."
                    : "."}
                </p>
              </div>
            </Col>
            <Col lg={6} md={6} sm={6} xs={12}>
              <div className="submitrequestimage mt-5">
                <Image
                  src={submitrequestImage}
                  alt="submitrequestImage"
                  className="submitrequestimg"
                ></Image>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* CHECK STATUS SECTION */}
      <div className="optoutSection mt-5">
        <Container>
          <Row>
            <Col lg={6} md={6} sm={6} xs={12} className="pr-3">
              <div className="optoutrequestimage mt-5">
                <Image
                  src={optoutrequestImage}
                  alt="optoutrequestImage"
                  className="optoutrequestimg"
                ></Image>
              </div>
            </Col>
            <Col lg={6} md={6} sm={6} xs={12}>
              <div className="optoutrequesttext mt-5">
                <h3 className="mb-4">{getText("checkStatusTitle")}</h3>
                <Button
                  href={
                    selectedlang
                      ? `${process.env.REACT_APP_WIREWHEEL_CHECK_STATUS_SPANISH_URL}`
                      : `${process.env.REACT_APP_WIREWHEEL_CHECK_STATUS_ENG_URL}`
                  }
                  target="_blank"
                  name="checkStatus"
                  className="btn check-status mb-2"
                >
                  {getText("checkStatusBtn")}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Bottom Notification Bar */}

      <div className="notificationbar">
        <div className="context">
          <h4 className="text-white context-heading">
            {getText("requestByCall")}
          </h4>
          <h4 className="text-white context-heading mt-4">
            {getText("requestByMail")}
          </h4>
        </div>

        <div className="downloadButton pt-3">
          <Button
            name="downloadInstructions"
            className="download-btn"
            href={
              selectedlang ? `/${selectedlang}/instructions` : `/instructions`
            }
          >
            {getText("downloadinstr")}
          </Button>
        </div>
      </div>
    </>
  );
}

export default ExerciseRights;
