import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import "./FraudAndSecurityDetails.css";
import FirstFSDetails from "../FirstFSDetails";
import ThirdFSDetails from "../ThirdFSDetails";
import FourthFSDetails from "../FourthFSDetails";
import SecondFSDetails from "../SecondFSDetails";

const initialState = {
  fsFirstItem: false,
  fsSecondItem: false,
  fsThirdItem: false,
  fsFourthItem: false,
  fsFifthItem: false,
};

function FraudAndSecurityDetails(props) {
  const { getText } = props;
  const [fsHovered, setFSHovered] = useState(initialState);

  return (
    <div>
      <ul className="fs-details-list">
        <li className="fs-details-item">
          <span>
            <p className="fs-details-item-title">
              {getText("bestPractices")}
              {!fsHovered.fsFirstItem ? (
                <FontAwesomeIcon
                  icon={faPlus}
                  size="1x"
                  className="fs-details-plus-icon"
                  onClick={() =>
                    setFSHovered({
                      ...initialState,
                      fsFirstItem: !initialState.fsFirstItem,
                    })
                  }
                />
              ) : (
                <FontAwesomeIcon
                  icon={faMinus}
                  size="1x"
                  className="fs-details-plus-icon"
                  onClick={() =>
                    setFSHovered({
                      ...initialState,
                      fsFirstItem: false,
                    })
                  }
                />
              )}
            </p>
          </span>
          {fsHovered.fsFirstItem && <FirstFSDetails getText={getText} />}
        </li>
        <li className="fs-details-item">
          <span>
            <p className="fs-details-item-title">
              {getText("commonScams")}
              {!fsHovered.fsSecondItem ? (
                <FontAwesomeIcon
                  icon={faPlus}
                  size="1x"
                  className="fs-details-plus-icon"
                  onClick={() =>
                    setFSHovered({
                      ...initialState,
                      fsSecondItem: !initialState.fsSecondItem,
                    })
                  }
                />
              ) : (
                <FontAwesomeIcon
                  icon={faMinus}
                  size="1x"
                  className="fs-details-plus-icon"
                  onClick={() =>
                    setFSHovered({
                      ...initialState,
                      fsSecondItem: false,
                    })
                  }
                />
              )}
            </p>
          </span>
          {fsHovered.fsSecondItem && <SecondFSDetails getText={getText} />}
        </li>
        <li className="fs-details-item">
          <span>
            <p className="fs-details-item-title">
              {getText("fraud")}
              {!fsHovered.fsThirdItem ? (
                <FontAwesomeIcon
                  icon={faPlus}
                  size="1x"
                  className="fs-details-plus-icon"
                  onClick={() =>
                    setFSHovered({
                      ...initialState,
                      fsThirdItem: !initialState.fsThirdItem,
                    })
                  }
                />
              ) : (
                <FontAwesomeIcon
                  icon={faMinus}
                  size="1x"
                  className="fs-details-plus-icon"
                  onClick={() =>
                    setFSHovered({
                      ...initialState,
                      fsThirdItem: false,
                    })
                  }
                />
              )}
            </p>
          </span>
          {fsHovered.fsThirdItem && <ThirdFSDetails getText={getText} />}
        </li>
        <li className="fs-details-item">
          <span>
            <p className="fs-details-item-title">
              {getText("addResources")}
              {!fsHovered.fsFourthItem ? (
                <FontAwesomeIcon
                  icon={faPlus}
                  size="1x"
                  className="fs-details-plus-icon"
                  onClick={() =>
                    setFSHovered({
                      ...initialState,
                      fsFourthItem: !initialState.fsFourthItem,
                    })
                  }
                />
              ) : (
                <FontAwesomeIcon
                  icon={faMinus}
                  size="1x"
                  className="fs-details-plus-icon"
                  onClick={() =>
                    setFSHovered({
                      ...initialState,
                      fsFourthItem: false,
                    })
                  }
                />
              )}
            </p>
          </span>
          {fsHovered.fsFourthItem && <FourthFSDetails getText={getText} />}
        </li>
        <li className="fs-details-item">
          <span>
            <p className="fs-details-item-title">
              {getText("protectInfo")}
              {!fsHovered.fsFifthItem ? (
                <FontAwesomeIcon
                  icon={faPlus}
                  size="1x"
                  className="fs-details-plus-icon"
                  onClick={() =>
                    setFSHovered({
                      ...initialState,
                      fsFifthItem: !initialState.fsFifthItem,
                    })
                  }
                />
              ) : (
                <FontAwesomeIcon
                  icon={faMinus}
                  size="1x"
                  className="fs-details-plus-icon"
                  onClick={() =>
                    setFSHovered({
                      ...initialState,
                      fsFifthItem: false,
                    })
                  }
                />
              )}
            </p>
          </span>
          {fsHovered.fsFifthItem && (
            <div className="fs-details-item-border">
              <ul className="fs-details-item-innerlist">
                <li className="fs-details-item-innerlist-item">
                  {getText("statement1")}
                </li>
                <li className="fs-details-item-innerlist-item">
                  {getText("statement2")}
                </li>
                <li className="fs-details-item-innerlist-item">
                  {getText("statement3")}
                </li>
              </ul>
            </div>
          )}
        </li>
      </ul>
    </div>
  );
}

export default FraudAndSecurityDetails;
