import React, { useContext } from "react";
import heroImage from "shared/images/3_CA_Privacy_Rights.webp";
import "./CaliforniaRejectionPage.scss";
import { languages } from "shared/languages/languages";
import { booleanContext } from "App";

function CaliforniaRejectionPage() {
  const selectedlang = useContext(booleanContext);

  const getText = (attr) => {
    if (selectedlang) {
      return languages[selectedlang].rejectionPage[attr];
    }
    return languages.en.rejectionPage[attr];
  };
  return (
    <>
      <div className="hero-banner">
        <img src={heroImage} alt="banner-img" className="hero-img" />
      </div>
      <div className="cal-page-title">{getText("pageTitle")}</div>
      <div className="ml-5 mt-5">
        <div className="carejection pt-5">
          <p className="carejectionintro mb-3">
            <strong>{getText("pageTitle")}</strong>
          </p>
          <p className="carejectionintro mb-3">{getText("introText")}</p>
          <p className="carejectionintro mt-5">
            {getText("policyText")}{" "}
            <a
              href="https://www.aflac.com/about-aflac/privacy-policy.aspx"
              data-toggle="tooltip"
              data-placement="bottom"
              target="_blank"
              rel="noreferrer noopener"
              title="https://www.aflac.com/about-aflac/privacy-policy.aspx"
            >
              {getText("privacyPolicy")}
            </a>
            .
          </p>
        </div>
      </div>
    </>
  );
}

export default CaliforniaRejectionPage;
