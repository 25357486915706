import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopyright,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faInstagramSquare,
  faYoutube,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import "./Footer.css";
import { Row, Col } from "react-bootstrap";
import ExternalShare from "../images/shareIcon.png";
import { languages } from "shared/languages/languages";
import { booleanContext } from "App";

function Footer() {
  const selectedlang = useContext(booleanContext);

  const getText = (attr) => {
    if (selectedlang) {
      return languages[selectedlang].footer[attr];
    }
    return languages.en.footer[attr];
  };

  const [viewLegal, setViewLegal] = useState(false);
  const showViewLegal = () => setViewLegal(!viewLegal);

  return (
    <footer>
      <div className="footer-container">
        <Row className="footer-row">
          <Col lg={5} md={5} sm={4} xs={4}>
            <ul className="footer-item-list">
              <li className="footer-item">
                <a
                  href="https://www.aflac.com/about-aflac/default.aspx"
                  className="external-link-a"
                >
                  {getText("aboutAflac")}
                  <img
                    src={ExternalShare}
                    alt="external-share"
                    className="external-share"
                  />
                </a>
              </li>
              <li className="footer-item">
                <a
                  href="https://www.aflac.com/careers/default.aspx"
                  className="external-link-a"
                >
                  {getText("careers")}
                  <img
                    src={ExternalShare}
                    alt="external-share"
                    className="external-share"
                  />
                </a>
              </li>
              <li className="footer-item">
                <a
                  href="https://investors.aflac.com/home/default.aspx"
                  className="external-link-a"
                >
                  {getText("investors")}{" "}
                  <img
                    src={ExternalShare}
                    alt="external-share"
                    className="external-share"
                  />
                </a>
              </li>
              <li className="footer-item">
                <a
                  href="https://www.duckgear.com/UserContentStart.aspx"
                  className="external-link-a"
                >
                  {getText("duckGear")}{" "}
                  <img
                    src={ExternalShare}
                    alt="external-share"
                    className="external-share"
                  />
                </a>
              </li>
              <li className="footer-item">
                {" "}
                <a
                  href="https://www.aflac.com/contact-aflac/default.aspx"
                  className="external-link-a"
                >
                  {getText("contactus")}{" "}
                </a>
              </li>
            </ul>
          </Col>
          <Col lg={4} md={4} sm={4} xs={4}>
            <ul className="footer-item-list">
              <li className="footer-item">
                {" "}
                <a
                  href="https://www.aflac.com/about-aflac/privacy-policy.aspx"
                  className="external-link-a"
                >
                  {getText("policyNot")}
                </a>
              </li>
              <li className="footer-item">
                {" "}
                <a
                  href="https://www.aflac.com/sitemap/default.aspx"
                  className="external-link-a"
                >
                  {getText("sitemap")}
                </a>
              </li>
              <li className="footer-item">
                {" "}
                <a
                  href="https://www.aflac.com/about-aflac/terms-of-use.aspx"
                  className="external-link-a"
                >
                  {getText("terms")}
                </a>{" "}
              </li>
              <li className="footer-item">
                {" "}
                <a
                  href="https://www.aflac.com/brandcenter/default.aspx"
                  className="external-link-a"
                >
                  {getText("brandcenter")}
                </a>{" "}
              </li>
            </ul>
          </Col>
          <Col lg={2} md={2} sm={4} xs={4}>
            {" "}
            <ul className="social mt-2">
              <li className="social-item">
                <a
                  href="https://www.facebook.com/aflacduck"
                  aria-label="Facebook"
                >
                  <FontAwesomeIcon icon={faFacebookF} className="social-link" />
                </a>
              </li>
              <li className="social-item">
                <a href="https://twitter.com/aflac" aria-label="Twitter">
                  <FontAwesomeIcon icon={faTwitter} className="social-link" />
                </a>
              </li>
              <li className="social-item">
                <a
                  href="https://www.linkedin.com/company/aflac/"
                  aria-label="Linkedin"
                >
                  <FontAwesomeIcon icon={faLinkedin} className="social-link" />
                </a>
              </li>
              <li className="social-item">
                <a
                  href="https://www.instagram.com/aflacduck/"
                  aria-label="Instagram"
                >
                  <FontAwesomeIcon
                    icon={faInstagramSquare}
                    className="social-link"
                  />
                </a>
              </li>
              <li className="social-item">
                <a
                  href="https://www.youtube.com/user/aflac"
                  aria-label="Youtube"
                >
                  <FontAwesomeIcon icon={faYoutube} className="social-link" />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </div>

      <div className="footer-copyright">
        <span className="copyright-label">
          <FontAwesomeIcon icon={faCopyright} /> {new Date().getFullYear()}{" "}
          {getText("aflacIncorporated")}
        </span>
        <span className="flex-size"></span>
        <span className="view-legal" onClick={showViewLegal}>
          <p>
            {getText("viewlegal")}
            {!viewLegal ? (
              <FontAwesomeIcon icon={faPlus} size="1x" className="ml-1" />
            ) : (
              <FontAwesomeIcon icon={faMinus} size="1x" className="ml-1" />
            )}
          </p>
        </span>
      </div>
      {viewLegal && (
        <div className="view-legal-description">
          {getText("individual")}
          <p>{getText("text1")}</p>
          <p>{getText("text2")}</p>
          {getText("group")}
          <p>{getText("text3")}</p>
          <p>{getText("text4")}</p>
          <p>{getText("text5")}</p>
        </div>
      )}
    </footer>
  );
}

export default Footer;
