import React, { useContext } from "react";
import Pdf from "react-to-pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import StdLog from "shared/images/bc_standard_logo.png";
import { Button } from "react-bootstrap";
import "./Instructions.css";
import { languages } from "shared/languages/languages";
import { booleanContext } from "App";

const ref = React.createRef();
function Instructions(props) {
  const handleBackButton = () => {
    props.history.goBack();
  };
  const selectedlang = useContext(booleanContext);

  const getText = (attr) => {
    if (selectedlang) {
      return languages[selectedlang].pdfPage[attr];
    }
    return languages.en.pdfPage[attr];
  };

  return (
    <>
      <Button className="back-buton" onClick={handleBackButton}>
        {getText("back")}{" "}
      </Button>
      <FontAwesomeIcon
        icon={faArrowLeft}
        className="ml-2 back-buton back-arrow"
      />
      <Pdf targetRef={ref} filename="Instructions.pdf" scale={0.6}>
        {({ toPdf }) => (
          <button onClick={toPdf} className="pdf-download-label">
            {getText("download")}
            <FontAwesomeIcon icon={faSave} className="ml-2 pdf-download" />{" "}
          </button>
        )}
      </Pdf>
      <div ref={ref}>
        <div className="pdf-border"></div>
        <div className="pdf-outer-body">
          <div className="pdf-header mb-5">
            <img src={StdLog} alt="std-logo" className="pdf-std-logo" />
            <p className="pdf-std-title">{getText("instructions")}</p>
          </div>
          <h3 className="pdf-sub-title mb-4">{getText("header1")}</h3>
          <h3 className="pdf-sub-title">{getText("header2")}</h3>
          <ul className="mail-request-item-list">
            <li className="mail-request-item">
              {getText("listText1")}
              <ul>
                <li className="mail-request-inner-item">
                  {getText("listItem1")}
                </li>
              </ul>
            </li>
            <li className="mail-request-item">{getText("listText2")}</li>
            <li className="mail-request-item">{getText("listText3")}</li>
            <li className="mail-request-item">
              {getText("listText4")}
              <ul>
                <li className="mail-request-inner-item">
                  {getText("listItem2")}
                </li>
                <li className="mail-request-inner-item">
                  {getText("listItem3")}
                </li>
              </ul>
            </li>
          </ul>

          <br />
          <h3 className="pdf-sub-title">{getText("header3")}</h3>
          <ul>
            <li className="mail-request-item">{getText("listText5")}</li>
            <li className="mail-request-item">
              {getText("listText6")}
              <ul>
                <li className="mail-request-inner-item">
                  {getText("listItem4")}
                </li>
              </ul>
            </li>
            <li className="mail-request-item">{getText("listText7")}</li>
            <li className="mail-request-item">{getText("listText8")}</li>
          </ul>
        </div>
        <div className="pdf-border"></div>
      </div>
    </>
  );
}

export default Instructions;
