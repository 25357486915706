import React, { useState } from "react";
import Popup from "shared/Popup/Popup";
import { Button } from "react-bootstrap";
import "./ContactUs.css";

function ContactUs(props) {
  const { getText } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenClose = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="notificationbar">
      <div className="context">
        <h4 className="text-white context-heading">
          {getText("contactBanner")}
        </h4>
      </div>
      <div className="downloadButton pt-3">
        <Button
          type="button"
          onClick={handleOpenClose}
          className="download-btn"
        >
          {getText("btnContact")}
        </Button>
      </div>

      {isOpen && (
        <Popup
          content={
            <>
              <div className="contact-grid">
                <div className="contact-me">
                  <b>{getText("physical")}</b>
                  <address>
                    {getText("Address1")} <br />
                    {getText("streetName")} <br />
                    {getText("postal")} <br />
                    800.992.3522
                  </address>
                </div>

                <div className="contact-me">
                  <b>{getText("mailing")}</b>
                  <address>
                    {getText("Address1")} <br />
                    {getText("privacyOffice")} <br />
                    {getText("streetName")} <br />
                    {getText("postal")} <br />
                    800.992.3522 <br />
                    <a
                      href="mailto:privacyoffice@aflac.com"
                      className="address-mail"
                    >
                      privacyoffice@aflac.com{" "}
                    </a>
                  </address>
                </div>

                <div className="contact-me">
                  <address>
                    {getText("groupAddress1")} <br />
                    {getText("groupAddress2")} <br />
                    {getText("groupAddress3")} <br />
                    800.433.3036
                  </address>
                </div>

                <div className="contact-me">
                  <address>
                    {getText("groupAddress1")} <br />
                    {getText("privacyOffice")} <br />
                    {getText("groupAddress5")} <br />
                    {getText("groupAddress6")} <br />
                    800.433.3036 <br />
                    <a
                      href="mailto:aflacgroupprivacyoffice@aflac.com"
                      className="address-mail"
                    >
                      aflacgroupprivacyoffice@aflac.com{" "}
                    </a>
                  </address>
                </div>
              </div>
            </>
          }
          handleOpenClose={handleOpenClose}
        />
      )}
    </div>
  );
}

export default ContactUs;
