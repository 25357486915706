import React from "react";

function VishingDetails(props) {
  const { getText } = props;
  return (
    <div className="fs-second-details-item">
      <p>{getText("vishingIntro")}</p>
      <p>{getText("themesText")}: </p>
      <ul className="fs-details-item-innerlist">
        <li className="fs-details-item-innerlist-item">
          {getText("vishingText1")}
        </li>
        <li className="fs-details-item-innerlist-item">
          {getText("vishingText2")}
        </li>
        <li className="fs-details-item-innerlist-item">
          {getText("vishingText3")}
        </li>
        <li className="fs-details-item-innerlist-item">
          {getText("vishingText4")}
        </li>
      </ul>
      <br />
      <p>{getText("protectYourself")}: </p>
      <ul>
        <li className="fs-details-item-innerlist-item">
          {getText("vishingText5")}
        </li>
        <li className="fs-details-item-innerlist-item">
          {getText("vishingText6")}
        </li>
        <li className="fs-details-item-innerlist-item">
          {getText("vishingText7")}{" "}
        </li>
        <li className="fs-details-item-innerlist-item">
          {getText("vishingText8")}
        </li>
      </ul>
    </div>
  );
}

export default VishingDetails;
