import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import HeaderNavbar from "shared/HeaderNavbar/HeaderNavbar";
import Footer from "shared/Footer/Footer";
import PrivacyCenter from "components/PrivacyCenter/PrivacyCenter";
import OuPrivacyPolicies from "components/OuPrivacyPolicies/OuPrivacyPolicies";
import FraudAndSecurity from "components/FraudAndSecurity/FraudAndSecurity";
import ExerciseRights from "components/ExerciseRights/ExerciseRights";
import SubmitRequestStart from "components/SubmitRequestStarter/SubmitRequestStart";
import OptOutRequestStart from "components/OptOutRequestStarter/OptOutRequestStart";
import CARejectionPage from "shared/components/CaliforniaRejectionPage/CaliforniaRejectionPage";
import Acknowledgment from "shared/components/Acknowledgment/Acknowledgment";
import SubmitRequest from "components/ExerciseRights/SubmitRequest/SubmitRequest";
import OptOutRequest from "components/ExerciseRights/OptOutRequest/OptOutRequest";
import Instructions from "components/PDFFile/Instructions";
import IEBrowswerNotSupportedMessage from "shared/components/IEBrowswerNotSupportedMessage";
import "./App.css";
export const booleanContext = React.createContext();

function App() {
  const pathArr = window.location.pathname.split("/");
  const selectedlang = pathArr.find((pathVal) => pathVal.length === 2);
  console.log("lang", selectedlang);

  useEffect(() => {
    let rootEl = document.querySelector("#root");
    if (localStorage.getItem("contrastmode") === null) {
      localStorage.setItem("contrastmode", "no");
    }
    rootEl &&
      (rootEl.className =
        localStorage.getItem("contrastmode") === "yes"
          ? "contrast-mode"
          : "non-contrast-mode");
  }, []);

  if (
    navigator.userAgent.indexOf("MSIE") !== -1 ||
    !!document.documentMode === true
  )
    return <IEBrowswerNotSupportedMessage />;
  else {
    return (
      <booleanContext.Provider value={selectedlang}>
        <Router>
          <HeaderNavbar />
          <Switch>
            <Route
              path={selectedlang ? `/${selectedlang}` : `/`}
              exact
              component={PrivacyCenter}
            />
            <Route
              path={
                selectedlang
                  ? `/${selectedlang}/ourprivacypolicies`
                  : `/ourprivacypolicies`
              }
              component={OuPrivacyPolicies}
            />
            <Route
              path={
                selectedlang
                  ? `/${selectedlang}/fraudandsecurity`
                  : `/fraudandsecurity`
              }
              component={FraudAndSecurity}
            />
            <Route
              path={
                selectedlang
                  ? `/${selectedlang}/exerciserights`
                  : `/exerciserights`
              }
              component={ExerciseRights}
            />
            <Route
              path={
                selectedlang
                  ? `/${selectedlang}/submitrequeststarter`
                  : `/submitrequeststarter`
              }
              component={SubmitRequestStart}
            />
            <Route
              path={
                selectedlang
                  ? `/${selectedlang}/optoutrequeststarter`
                  : `/optoutrequeststarter`
              }
              component={OptOutRequestStart}
            />
            <Route
              path={
                selectedlang
                  ? `/${selectedlang}/submitrequest`
                  : `/submitrequest`
              }
              component={SubmitRequest}
            />
            <Route
              path={
                selectedlang
                  ? `/${selectedlang}/acknowledgment`
                  : `/acknowledgment`
              }
              component={Acknowledgment}
            />
            <Route
              path={
                selectedlang
                  ? `/${selectedlang}/optoutrequest`
                  : `/optoutrequest`
              }
              component={OptOutRequest}
            />
            <Route
              path={
                selectedlang
                  ? `/${selectedlang}/noncaliforniaresident`
                  : `/noncaliforniaresident`
              }
              component={CARejectionPage}
            />
            <Route
              path={
                selectedlang ? `/${selectedlang}/instructions` : `/instructions`
              }
              component={Instructions}
            />

            {/* catch all */}
            <Redirect
              path
              from="*"
              to={selectedlang ? `/${selectedlang}` : `/`}
              component={PrivacyCenter}
            />
          </Switch>
          <Footer />
        </Router>
      </booleanContext.Provider>
    );
  }
}

export default App;
