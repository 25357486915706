import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReCaptcha from "shared/components/ReCaptcha";
import "./SubmitRequest.css";
import {
  textValidation,
  emailValidation,
  zipcodeValidation,
  textValidationES,
  emailValidationES,
  zipcodeValidationES,
} from "./Validation";
import { getService } from "shared/library/restApi";
import getPayload from "shared/library/getPayload";

function CAForm(props) {
  const {
    getSubmitRequestId,
    getAcknowledgement,
    enableLoader,
    getText,
    getRelationshipType,
    selectedlang,
    configValues,
    getSelectedRequestType,
  } = props;

  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isRequiredSpDp, setIsRequiredSpDp] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState("");
  const [relationshipValue, setRelationshipValue] = useState("");
  const [sendRequest, setSendRequest] = useState(false);
  const [updateRequestType, setUpdateRequestType] = useState(false);

  const handleReCaptchaClick = (value) => {
    setDisableSubmit(!value);
  };

  // Make Spouse/Dependent fields as required when they are selected
  const handleSpouseDependent = (e) => {
    if (e.target.value === "Spouse" || e.target.value === "Dependent") {
      setIsRequiredSpDp(true);
    } else {
      setIsRequiredSpDp(false);
    }
  };

  const { register, handleSubmit, formState, errors, validate } = useForm({
    mode: "onChange",
  });

  const _onFocus = (e) => {
    e.currentTarget.type = "date";
  };

  const _onBlur = (e) => {
    e.currentTarget.type = "text";
    e.currentTarget.placeholder = getText("dob");
  };

  // handling form onsubmit event

  const onSubmit = (data) => {
    setSendRequest(data);
  };

  useEffect(() => {
    if (sendRequest) {
      // activate the loader
      enableLoader(true);
      console.log("sendRequestData", sendRequest);
      // payload
      const inputsForPayLoad = {
        selectedRequest,
        selectedlang,
        inputsData: sendRequest,
        configValues,
      };

      const payload = getPayload(inputsForPayLoad);
      console.log("payload", payload);

      // API request

      const url = `${process.env.REACT_APP_WIREWHEEL_URL}`;

      const submitOptions = {
        method: `POST`,
        url,
        Headers: {
          "Access-Control-Allow-Origin": "*",
        },
        data: payload,
      };

      const submitResponse = getService(submitOptions);
      submitResponse
        .then((response) => {
          if (response.status === 201 && response.data) {
            // get the root id after successful request submit
            getSubmitRequestId(response.data.rootId);
          }
        })
        .then(() => {
          // deactivate the loader and enable the Acknowledgement
          enableLoader(false);
          getAcknowledgement(true);
        })
        .catch((err) => {
          console.error(err);
          // TODO: show error to user
        });
    }
    return () => {
      setSendRequest(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendRequest]);

  const handleRelationship = (e) => {
    getRelationshipType(e.target.value);
    setRelationshipValue(e.target.value);
  };

  const handleRequestTypes = (e) => {
    setSelectedRequest(e.target.value);
    getSelectedRequestType(e.target.value);
  };

  useEffect(() => {
    if (selectedRequest) {
      if (selectedRequest === "correction") {
        setUpdateRequestType(true);
      } else {
        setUpdateRequestType(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRequest]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mt-4">
          <Row className="w-75">
            <Col>
              <Form.Label as="legend" className="radio-label font-weight-bold">
                {getText("relationshipToAflac")}{" "}
                <span className="text-danger">*</span>
              </Form.Label>
              <Row className="ml-2">
                <Col>
                  <Form.Check
                    type="radio"
                    inline
                    label={getText("customer")}
                    name="relationshipToAflac"
                    id="customer"
                    value={getText("customer")}
                    className="left radio-label-item white-space-nowrap"
                    onClick={handleRelationship}
                    ref={register({ required: true })}
                  />
                </Col>
              </Row>
              <Row className="ml-2">
                {" "}
                <Col>
                  <Form.Check
                    type="radio"
                    inline
                    label={getText("employee")}
                    name="relationshipToAflac"
                    value={getText("employee")}
                    className="left radio-label-item white-space-nowrap"
                    id="employee"
                    onClick={handleRelationship}
                    ref={register({ required: true })}
                  />
                </Col>
              </Row>
              <Row className="ml-2">
                {" "}
                <Col>
                  <Form.Check
                    type="radio"
                    inline
                    label={getText("jobApplicant")}
                    name="relationshipToAflac"
                    value={getText("jobApplicant")}
                    className="left radio-label-item white-space-nowrap"
                    id="jobApplicant"
                    onClick={handleRelationship}
                    ref={register({ required: true })}
                  />
                </Col>
              </Row>
              <Row className="ml-2">
                <Col>
                  <Form.Check
                    type="radio"
                    inline
                    label={getText("salesBroker")}
                    name="relationshipToAflac"
                    value={getText("salesBroker")}
                    className="left radio-label-item white-space-nowrap"
                    id="salesAgentBroker"
                    onClick={handleRelationship}
                    ref={register({ required: true })}
                  />
                </Col>
              </Row>
              <Row className="ml-2">
                <Col>
                  <Form.Check
                    type="radio"
                    inline
                    label={getText("contractor")}
                    name="relationshipToAflac"
                    value={getText("contractor")}
                    className="left radio-label-item white-space-nowrap"
                    id="contractor"
                    onClick={handleRelationship}
                    ref={register({ required: true })}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form.Group>
        {relationshipValue ? (
          <>
            <Form.Group>
              <Row>
                <Col>
                  <Form.Label
                    as="legend"
                    className="radio-label font-weight-bold"
                  >
                    {getText("requestType")}{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Row className="ml-2">
                    <Col>
                      <Form.Check id="access">
                        <Form.Check.Input
                          type="radio"
                          inline
                          name="requestType"
                          value="access"
                          onClick={handleRequestTypes}
                          className="left radio-label-item white-space-nowrap"
                          ref={register({ required: true })}
                        />
                        <Form.Check.Label className="left radio-label-item white-space-nowrap mb-2">
                          <strong>{getText("access")}</strong> -{" "}
                          {getText("accessDescription")}
                        </Form.Check.Label>
                      </Form.Check>
                    </Col>
                  </Row>
                  <Row className="ml-2">
                    {" "}
                    <Col>
                      <Form.Check id="delete">
                        <Form.Check.Input
                          type="radio"
                          inline
                          name="requestType"
                          value="deletion"
                          onClick={handleRequestTypes}
                          className="left radio-label-item white-space-nowrap"
                          ref={register({ required: true })}
                        />
                        <Form.Check.Label className="left radio-label-item white-space-nowrap mb-2">
                          <strong>{getText("delete")}</strong> -{" "}
                          {getText("deleteDescription")}
                        </Form.Check.Label>
                      </Form.Check>
                    </Col>
                  </Row>
                  <Row className="ml-2">
                    {" "}
                    <Col>
                      <Form.Check id="optOut">
                        <Form.Check.Input
                          type="radio"
                          inline
                          name="requestType"
                          value="optOut"
                          onClick={handleRequestTypes}
                          ref={register({ required: true })}
                          className="left radio-label-item white-space-nowrap"
                        />
                        <Form.Check.Label className="left radio-label-item white-space-nowrap mb-2">
                          <strong>{getText("optOut")}</strong> -{" "}
                          {getText("optOutDescription")}
                        </Form.Check.Label>
                      </Form.Check>
                    </Col>
                  </Row>
                  <Row className="ml-2">
                    <Col>
                      <Form.Check id="correction">
                        <Form.Check.Input
                          type="radio"
                          inline
                          name="requestType"
                          value="correction"
                          onClick={handleRequestTypes}
                          className="left radio-label-item white-space-nowrap"
                          ref={register({ required: true })}
                        />
                        <Form.Check.Label className="left radio-label-item white-space-nowrap mb-2">
                          <strong>{getText("correction")}</strong> -{" "}
                          {getText("correctionDescription")}
                        </Form.Check.Label>
                      </Form.Check>
                    </Col>
                  </Row>
                  <Row className="ml-2">
                    <Col>
                      <Form.Check id="optOut-data-processing">
                        <Form.Check.Input
                          type="radio"
                          inline
                          name="requestType"
                          value="optOut-data-processing"
                          className="left radio-label-item white-space-nowrap"
                          onClick={handleRequestTypes}
                          ref={register({ required: true })}
                        />
                        <Form.Check.Label className="left radio-label-item white-space-nowrap mb-2">
                          <strong>{getText("optOutDataProcessing")}</strong> -{" "}
                          {getText("optOutDataProcessingDescription")}
                        </Form.Check.Label>
                      </Form.Check>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form.Group>

            {updateRequestType ? (
              <Form.Group className="mb-3">
                <Row xs={1}>
                  <Col className="mb-2">
                    <Form.Label
                      as="legend"
                      className="radio-label font-weight-bold"
                    >
                      {getText("specifyUpdateReq")}{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="updateRequestDetails"
                      className="mb-2 w-75 form-input-field ml-3 updateBox"
                      placeholder={getText("specifyUpdateReqPlaceholder")}
                      ref={register({ required: true })}
                    />
                  </Col>
                </Row>
              </Form.Group>
            ) : (
              ""
            )}

            {selectedRequest && (
              <>
                {relationshipValue === getText("customer") &&
                  (selectedRequest === "access" ||
                    selectedRequest === "deletion" ||
                    selectedRequest === "correction") && (
                    <>
                      <fieldset>
                        <Form.Group>
                          <Form.Label
                            as="legend"
                            className="radio-label font-weight-bold"
                          >
                            {getText("recentStatus")}{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Row className="w-50 ml-2">
                            <Col>
                              <Form.Check
                                type="radio"
                                inline
                                label={getText("aflacIndividual")}
                                name="status"
                                id="individual"
                                value="Aflac Individual"
                                className="left radio-label-item white-space-nowrap"
                                ref={register({ required: true })}
                              />
                            </Col>
                          </Row>
                          <Row className="ml-2">
                            {" "}
                            <Col>
                              <Form.Check
                                type="radio"
                                inline
                                label={getText("aflacGroup")}
                                name="status"
                                id="group"
                                value="Aflac Group"
                                className="text-center radio-label-item"
                                ref={register({ required: true })}
                              />
                            </Col>
                          </Row>
                          <Row className="ml-2">
                            {" "}
                            <Col>
                              <Form.Check
                                type="radio"
                                inline
                                label={getText("notSure")}
                                name="status"
                                value="Not Sure"
                                id="notsure"
                                className="left radio-label-item"
                                ref={register({ required: true })}
                              />
                            </Col>
                          </Row>
                          <Row className="ml-2">
                            <Col lg="2" md="2" sm="2">
                              <Form.Check
                                type="radio"
                                inline
                                label={getText("both")}
                                name="status"
                                value="Both"
                                id="both"
                                className="mr-5 radio-label-item"
                                ref={register({ required: true })}
                              />
                            </Col>
                          </Row>
                          <Row className="ml-2">
                            {" "}
                            <Col lg="2" md="2" sm="2">
                              <Form.Check
                                type="radio"
                                inline
                                label={getText("neither1")}
                                name="status"
                                value="Neither"
                                id="neigther"
                                className="right radio-label-item"
                                ref={register({ required: true })}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      </fieldset>
                      <fieldset>
                        <Form.Group>
                          <Form.Label
                            as="legend"
                            className="radio-label font-weight-bold "
                          >
                            {getText("personType")}{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>

                          <Row className="ml-2">
                            <Col lg="2" md="2">
                              <Form.Check
                                type="radio"
                                inline
                                label={getText("primary")}
                                value="Primary"
                                id="primary"
                                name="coverage"
                                className="mr-5 radio-label-item"
                                ref={register({ required: true })}
                                onClick={handleSpouseDependent}
                              />
                            </Col>
                          </Row>
                          <Row className="ml-2">
                            {" "}
                            <Col lg="2" md="2">
                              <Form.Check
                                type="radio"
                                inline
                                label={getText("spouse")}
                                value="Spouse"
                                id="spouse"
                                name="coverage"
                                className="right radio-label-item"
                                ref={register({ required: true })}
                                onClick={handleSpouseDependent}
                              />
                            </Col>
                          </Row>
                          <Row className="ml-2">
                            <Col lg="2" md="2">
                              <Form.Check
                                type="radio"
                                inline
                                label={getText("dependent")}
                                value="Dependent"
                                id="dependent"
                                name="coverage"
                                className="mr-5 radio-label-item"
                                ref={register({ required: true })}
                                onClick={handleSpouseDependent}
                              />
                            </Col>
                          </Row>
                          <Row className="ml-2">
                            {" "}
                            <Col lg="2" md="2">
                              <Form.Check
                                type="radio"
                                inline
                                label={getText("neither2")}
                                value="Neither"
                                id="neigther2"
                                name="coverage"
                                className="right radio-label-item"
                                ref={register({ required: true })}
                                onClick={handleSpouseDependent}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      </fieldset>
                      {isRequiredSpDp ? (
                        <Form.Group>
                          <Form.Row className="w-75  ml-3">
                            <Col className="marginright-4">
                              <Form.Control
                                type="text"
                                name="sdFirstName"
                                placeholder={getText("spouseDepPlaceholderFN")}
                                className={
                                  !errors.sdFirstName
                                    ? "form-input-field"
                                    : "form-input-field error-border"
                                }
                                ref={
                                  selectedlang
                                    ? register(textValidationES)
                                    : register(textValidation)
                                }
                              />

                              {errors.sdFirstName && (
                                <small className="text-danger">
                                  {" "}
                                  {errors.sdFirstName.message}{" "}
                                </small>
                              )}
                            </Col>

                            <Col className="marginleft-4">
                              <Form.Control
                                type="text"
                                name="sdLastName"
                                placeholder={getText("spouseDepPlaceholderLN")}
                                className={
                                  !errors.sdLastName
                                    ? "form-input-field"
                                    : "form-input-field error-border"
                                }
                                ref={
                                  selectedlang
                                    ? register(textValidationES)
                                    : register(textValidation)
                                }
                              />
                              {errors.sdLastName && (
                                <small className="text-danger">
                                  {errors.sdLastName.message}
                                </small>
                              )}
                            </Col>
                          </Form.Row>
                        </Form.Group>
                      ) : (
                        ""
                      )}
                    </>
                  )}

                {relationshipValue === getText("employee") &&
                  (selectedRequest === "access" ||
                    selectedRequest === "deletion" ||
                    selectedRequest === "correction") && (
                    <>
                      <fieldset>
                        <Form.Group>
                          <Form.Label
                            as="legend"
                            className="radio-label font-weight-bold"
                          >
                            {getText("eNumber")}
                          </Form.Label>
                          <Form.Row className="w-75 ml-3 mt-3">
                            <Col className="marginright-4">
                              <Form.Control
                                type="text"
                                name="eNumber"
                                placeholder="E-Number"
                                className={
                                  !errors.eNumber
                                    ? "form-input-field"
                                    : "form-input-field error-border"
                                }
                                ref={register({ required: false })}
                              />
                            </Col>

                            <Col className="marginleft-4">
                              <Form.Control
                                type="text"
                                name="ssnNumber"
                                placeholder={getText("ssnDigits")}
                                className={
                                  !errors.ssnNumber
                                    ? "form-input-field updateBox"
                                    : "form-input-field error-border updateBox"
                                }
                                ref={register({ required: false })}
                              />
                            </Col>
                          </Form.Row>
                        </Form.Group>
                      </fieldset>
                    </>
                  )}

                {relationshipValue === getText("salesBroker") &&
                  (selectedRequest === "access" ||
                    selectedRequest === "deletion" ||
                    selectedRequest === "correction") && (
                    <>
                      <fieldset>
                        <Form.Group>
                          <Form.Label
                            as="legend"
                            className="radio-label font-weight-bold"
                          >
                            {getText("writingNumberLabel")}
                          </Form.Label>
                          <Form.Row className="w-75 ml-3 mt-3">
                            <Col className="marginright-4">
                              <Form.Control
                                type="text"
                                name="writingNumber"
                                placeholder={getText("writingNumber")}
                                className={
                                  !errors.writingNumber
                                    ? "form-input-field"
                                    : "form-input-field error-border"
                                }
                                ref={register({ required: false })}
                              />
                            </Col>

                            <Col className="marginleft-4">
                              <Form.Control
                                type="text"
                                name="ssntinNumber"
                                placeholder={getText("ssntnDigits")}
                                className={
                                  !errors.ssntinNumber
                                    ? "form-input-field updateBox"
                                    : "form-input-field error-border updateBox"
                                }
                                ref={register({ required: false })}
                              />
                            </Col>
                          </Form.Row>
                        </Form.Group>
                      </fieldset>
                    </>
                  )}

                {relationshipValue === getText("contractor") &&
                  (selectedRequest === "access" ||
                    selectedRequest === "deletion" ||
                    selectedRequest === "correction") && (
                    <>
                      <fieldset>
                        <Form.Group>
                          <Form.Label
                            as="legend"
                            className="radio-label font-weight-bold"
                          >
                            {getText("vNumber")}
                          </Form.Label>
                          <Form.Row className="w-75 ml-3 mt-3">
                            <Col className="marginright-4">
                              <Form.Control
                                name="vNumber"
                                placeholder="V-Number"
                                className={
                                  !errors.vNumber
                                    ? "form-input-field"
                                    : "form-input-field error-border"
                                }
                                ref={register({ required: false })}
                              />
                            </Col>
                            <Col className="marginright-4"></Col>
                          </Form.Row>
                        </Form.Group>
                      </fieldset>
                    </>
                  )}

                <Form.Group>
                  <Form.Label className="radio-label font-weight-bold ">
                    {getText("email")}
                  </Form.Label>
                  <Form.Row className="w-75 ml-3">
                    <Col className="marginright-4">
                      <Form.Control
                        name="email"
                        type="email"
                        placeholder={getText("emailPlaceholder")}
                        className={
                          !errors.email
                            ? "form-input-field mb-2"
                            : "form-input-field error-border mb-2"
                        }
                        ref={
                          selectedlang
                            ? register(emailValidationES)
                            : register(emailValidation)
                        }
                      />
                      {errors.email && (
                        <small className="text-danger">
                          {errors.email.message}
                        </small>
                      )}
                    </Col>
                    <Col className="marginleft-4"></Col>
                  </Form.Row>
                  <strong className="required-field-label marginleft-4">
                    {getText("emailText")}
                  </strong>
                </Form.Group>

                {relationshipValue === getText("customer") && (
                  <Form.Label className="radio-label font-weight-bold mb-3">
                    {getText("enterpriInsuredInfo")}{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                )}

                <Form.Group>
                  <Form.Row className="w-75 ml-3">
                    <Col className="marginright-4">
                      <Form.Control
                        type="text"
                        name="primaryFirstName"
                        placeholder={getText("firstName")}
                        className={
                          !errors.primaryFirstName
                            ? "form-input-field"
                            : "form-input-field error-border"
                        }
                        ref={
                          selectedlang
                            ? register(textValidationES)
                            : register(textValidation)
                        }
                      />

                      {errors.primaryFirstName && (
                        <small className="text-danger">
                          {" "}
                          {errors.primaryFirstName.message}{" "}
                        </small>
                      )}
                    </Col>

                    <Col className="marginleft-4">
                      <Form.Control
                        type="text"
                        name="primaryLastName"
                        placeholder={getText("lastName")}
                        className={
                          !errors.primaryLastName
                            ? "form-input-field"
                            : "form-input-field error-border"
                        }
                        ref={
                          selectedlang
                            ? register(textValidationES)
                            : register(textValidation)
                        }
                      />
                      {errors.primaryLastName && (
                        <small className="text-danger">
                          {errors.primaryLastName.message}
                        </small>
                      )}
                    </Col>
                  </Form.Row>
                </Form.Group>

                <Form.Group>
                  <Form.Row className="w-75  ml-3 ">
                    <Col className="marginright-4">
                      <Form.Control
                        type="text"
                        name="address1"
                        placeholder={getText("address1")}
                        className={
                          !errors.address1
                            ? "form-input-field"
                            : "form-input-field error-border"
                        }
                        ref={register({ required: true })}
                      />

                      {errors.address1 &&
                        errors.address1.type === "required" && (
                          <small className="text-danger">
                            {selectedlang
                              ? `Este campo es obligatorio`
                              : `This field is required`}
                          </small>
                        )}
                    </Col>
                    <Col className="marginleft-4">
                      <Form.Control
                        type="text"
                        name="address2"
                        placeholder={getText("address2")}
                        className="form-input-field"
                        ref={register({ required: false })}
                      />
                    </Col>
                  </Form.Row>
                </Form.Group>

                <Form.Group>
                  <Form.Row className="w-75 ml-3">
                    <Col className="marginright-4">
                      <Form.Control
                        type="text"
                        name="city"
                        placeholder={getText("city")}
                        className={
                          !errors.city
                            ? "form-input-field"
                            : "form-input-field error-border"
                        }
                        ref={
                          selectedlang
                            ? register(textValidationES)
                            : register(textValidation)
                        }
                      />

                      {errors.city && (
                        <small className="text-danger">
                          {" "}
                          {errors.city.message}{" "}
                        </small>
                      )}
                    </Col>
                    <Col className="marginleft-4">
                      <Form.Control
                        name="state"
                        type="text"
                        placeholder={getText("state")}
                        value="California"
                        defaultValue="California"
                        className="form-input-field"
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Form.Row>
                </Form.Group>

                <Form.Group>
                  <Form.Row className="w-75  ml-3 mr-5">
                    <Col className="marginright-4">
                      <Form.Control
                        type="text"
                        name="postalzipcode"
                        placeholder={getText("zipCode")}
                        className={
                          !errors.postalzipcode
                            ? "form-input-field"
                            : "form-input-field error-border"
                        }
                        ref={
                          selectedlang
                            ? register(zipcodeValidationES)
                            : register(zipcodeValidation)
                        }
                      />

                      {errors.postalzipcode && (
                        <small className="text-danger">
                          {" "}
                          {errors.postalzipcode.message}
                        </small>
                      )}
                    </Col>
                    <Col className="marginleft-4">
                      <Form.Control
                        name="country"
                        type="text"
                        placeholder={getText("country")}
                        value="United States of America"
                        defaultValue="United States of America"
                        className="form-input-field"
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Form.Row>
                </Form.Group>

                <Form.Group>
                  <Form.Row className="w-75 ml-3">
                    <Col className="marginright-4">
                      <Form.Control
                        type="text"
                        name="dob"
                        placeholder={getText("dob")}
                        onFocus={_onFocus}
                        onBlur={_onBlur}
                        className={
                          !errors.dob
                            ? "form-input-field dob-fieldz"
                            : "form-input-field error-border dob-fieldz "
                        }
                        ref={register({ required: "true" })}
                      />
                      {errors.dob && (
                        <small className="text-danger">
                          {selectedlang
                            ? `Este campo es obligatorio`
                            : `This field is required`}
                        </small>
                      )}
                    </Col>

                    <Col className="marginleft-4"></Col>
                  </Form.Row>
                </Form.Group>

                <strong className="required-field-label marginleft-4">
                  {getText("required")}
                </strong>

                <ReCaptcha
                  reCaptchaClick={handleReCaptchaClick}
                  selectedlang={selectedlang}
                />
                <br />
                <br />
                <Form.Group>
                  <Row className="w-75 ml-3 justify-content-center">
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <Button
                        type="submit"
                        className="form-btn submit-btn marginright-4"
                        disabled={
                          !formState.isValid ||
                          disableSubmit ||
                          validate ||
                          !selectedRequest.length
                        }
                      >
                        {getText("submitBtn")}
                      </Button>
                    </Col>

                    <Col lg={6} md={6} sm={6} xs={6}>
                      <Button
                        className="form-btn cancel-btn marginleft-4"
                        href={
                          selectedlang
                            ? `/${selectedlang}/exerciserights`
                            : `/exerciserights`
                        }
                      >
                        {getText("cancelBtn")}
                      </Button>
                    </Col>
                  </Row>
                </Form.Group>
              </>
            )}
          </>
        ) : (
          ""
        )}
      </form>
    </>
  );
}

export default CAForm;
