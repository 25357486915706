import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import PrivacyPolicyIcon from "../../../shared/images/PrivacyPolicyIcon.svg";
import PrivacyNoticeIcon from "../../../shared/images/PrivacyNoticeIcon.svg";
import PrivacyNotificationsIcon from "../../../shared/images/PrivacyNotificationsIcon.svg";
import CookiesTrackingIcon from "../../../shared/images/CookiesTrackingIcon.svg";
import CareersPrivacyAgreementIcon from "../../../shared/images/CareersPrivacyAgreementIcon.svg";
import TermsofUseIcon from "../../../shared/images/TermsofUseIcon.svg";
import "./OurPolicyCards.css";

function OurPolicyCards(props) {
  const { getText } = props;
  return (
    <div className="card-backgorund" style={{ background: "#e1f7ff" }}>
      <Container>
        <Row lg="2" md="1" sm="1" xs="1">
          <Col className="our-privacy-col">
            <a
              href="https://www.aflac.com/about-aflac/privacy-policy.aspx"
              target="_blank"
              rel="noreferrer noopener"
              className="opp-card-link"
            >
              <Card
                style={{
                  width: "100%",
                  height: "11rem",
                  marginBottom: "1.5rem",
                  display: "flex",
                  flexDirection: "row",
                }}
                className="our-privacy-card"
              >
                <img
                  src={PrivacyPolicyIcon}
                  alt="privacypolicy-icon"
                  className="policy-card-icon"
                />{" "}
                <Card.Body className="policy-card-body">
                  <Card.Title className="our-privacy-policy-title">
                    {getText("privacyPolicy")}
                  </Card.Title>
                  <Card.Text className="our-privacy-policy-text">
                    {getText("privacyPolicyDesc")}
                  </Card.Text>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col>
            <a
              href="https://www.aflac.com/about-aflac/privacy-notices.aspx"
              target="_blank"
              rel="noreferrer noopener"
              className="opp-card-link"
            >
              <Card
                style={{
                  width: "100%",
                  height: "11rem",
                  marginBottom: "1.5rem",
                  display: "flex",
                  flexDirection: "row",
                }}
                className="our-privacy-card"
              >
                <img
                  src={PrivacyNoticeIcon}
                  alt="privacypolicy-icon"
                  className="policy-card-icon"
                />{" "}
                <Card.Body className="policy-card-body">
                  <Card.Title className="our-privacy-policy-title">
                    {getText("privacyNotice")}
                  </Card.Title>
                  <Card.Text className="our-privacy-policy-text">
                    {getText("privacyNoticeDesc")}
                  </Card.Text>
                </Card.Body>
              </Card>
            </a>
          </Col>
        </Row>
        <Row lg="2" md="1" sm="1" xs="1">
          <Col>
            <a
              href="https://www.aflac.com/about-aflac/privacy-notifications.aspx"
              target="_blank"
              rel="noreferrer noopener"
              className="opp-card-link"
            >
              <Card
                style={{
                  width: "100%",
                  height: "11rem",
                  marginBottom: "1.5rem",
                  display: "flex",
                  flexDirection: "row",
                }}
                className="our-privacy-card"
              >
                <img
                  src={PrivacyNotificationsIcon}
                  alt="privacypolicy-icon"
                  className="policy-card-icon"
                />{" "}
                <Card.Body className="policy-card-body">
                  <Card.Title className="our-privacy-policy-title">
                    {getText("privacyNotification")}
                  </Card.Title>
                  <Card.Text className="our-privacy-policy-text">
                    {getText("privacyNotificationDesc")}
                  </Card.Text>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col>
            <a
              href="https://www.aflac.com/about-aflac/careers-privacy-agreement.aspx"
              className="opp-card-link"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Card
                style={{
                  width: "100%",
                  height: "11rem",
                  marginBottom: "1.5rem",
                  display: "flex",
                  flexDirection: "row",
                }}
                className="our-privacy-card"
              >
                <img
                  src={CareersPrivacyAgreementIcon}
                  alt="privacypolicy-icon"
                  className="policy-card-icon"
                />{" "}
                <Card.Body className="policy-card-body">
                  <Card.Title className="our-privacy-policy-title">
                    {getText("careersAgreement")}
                  </Card.Title>
                  <Card.Text className="our-privacy-policy-text">
                    {getText("careersAgreementDesc")}
                  </Card.Text>
                </Card.Body>
              </Card>
            </a>
          </Col>
        </Row>
        <Row lg="2" md="1" sm="1" xs="1">
          <Col>
            <a
              href="https://www.aflac.com/about-aflac/terms-of-use.aspx"
              target="_blank"
              rel="noreferrer noopener"
              className="opp-card-link"
            >
              <Card
                style={{
                  width: "100%",
                  height: "11rem",
                  marginBottom: "1.5rem",
                  display: "flex",
                  flexDirection: "row",
                }}
                className="our-privacy-card"
              >
                <img
                  src={TermsofUseIcon}
                  alt="privacypolicy-icon"
                  className="policy-card-icon"
                />{" "}
                <Card.Body className="policy-card-body">
                  <Card.Title className="our-privacy-policy-title">
                    {getText("terms")}
                  </Card.Title>
                  <Card.Text className="our-privacy-policy-text">
                    {getText("termsDesc")}
                  </Card.Text>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col>
            <a
              href="https://www.aflac.com/about-aflac/cookies-and-tracking-technology-policy.aspx"
              target="_blank"
              rel="noreferrer noopener"
              className="opp-card-link"
            >
              <Card
                style={{
                  width: "100%",
                  height: "11rem",
                  marginBottom: "1.5rem",
                  display: "flex",
                  flexDirection: "row",
                }}
                className="our-privacy-card"
              >
                <img
                  src={CookiesTrackingIcon}
                  alt="privacypolicy-icon"
                  className="policy-card-icon"
                />{" "}
                <Card.Body className="policy-card-body">
                  <Card.Title className="our-privacy-policy-title">
                    {getText("cookiesTracking")}
                  </Card.Title>
                  <Card.Text className="our-privacy-policy-text">
                    {getText("cookiesTrackingDesc")}
                  </Card.Text>
                </Card.Body>
              </Card>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default OurPolicyCards;
