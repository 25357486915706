import React from "react";
import "./Popup.css";
const Popup = (props) => {
  return (
    <div className="popup-box" onClick={props.handleOpenClose}>
      <div className="box">{props.content}</div>
    </div>
  );
};

export default Popup;
