import React, { useContext } from "react";
import HeroBanner from "shared/HeroBanner/HeroBanner";
import PrivacyCenterCard from "./PrivacyCenterCard/PrivacyCenterCard";
import ContactUs from "./ContactUs/ContactUs";
import Trust_Aflac_img from "shared/images/2a_Trust_Aflac.webp";
import WME_2020 from "shared/images/WME_2020_logo.jpg";
import "./PrivacyCenter.css";
import { languages } from "shared/languages/languages";
import { booleanContext } from "App";

function PrivacyCenter() {
  const selectedlang = useContext(booleanContext);

  const getText = (attr) => {
    if (selectedlang) {
      return languages[selectedlang].privacycenter[attr];
    }
    return languages.en.privacycenter[attr];
  };

  return (
    <div className="privacycenterzzz">
      <HeroBanner title={getText("bannerTitle")} img={Trust_Aflac_img} />
      <div className="page-title">{getText("pageTitle")}</div>
      <br />
      <div>
        <p className="page-description">{getText("pageDescription")}</p>
        <img src={WME_2020} alt="wme2020-img" className="apc-title-img" />
        <br />
        <div>
          <PrivacyCenterCard getText={getText} />
          <ContactUs getText={getText} />
        </div>
      </div>
    </div>
  );
}

export default PrivacyCenter;
