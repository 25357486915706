import React, { useContext } from "react";
import StarterPage from "shared/components/starterpage/StarterPage";
import { languages } from "shared/languages/languages";
import { booleanContext } from "App";

function OptOutRequestStart() {
  const digitalText = false;
  const selectedlang = useContext(booleanContext);

  const getText = (attr) => {
    if (selectedlang) {
      return languages[selectedlang].optoutRequestStart[attr];
    }
    return languages.en.optoutRequestStart[attr];
  };

  return (
    <div>
      <StarterPage
        introText={
          <>
            <p>{getText("introText1")}</p>
            <p>{getText("introText2")}</p>
            <p>{getText("introText3")}</p>
          </>
        }
        digitalText={digitalText}
      />
    </div>
  );
}

export default OptOutRequestStart;
