// importing packages
import React, { useState, useEffect, useContext } from "react";
import { Spinner } from "react-bootstrap";
import { languages } from "shared/languages/languages";
import { booleanContext } from "App";

// importing modules
import "../SubmitRequest/SubmitRequest.css";
import OptOutForm from "./OptOutForm";
import Acknowledgement from "shared/components/Acknowledgment/Acknowledgment";
import { getService } from "shared/library/restApi";

function OptOutRequest() {
  const [showAcknowledgement, setShowAcknowledgement] = useState(false);
  const [id, setId] = useState("");
  const [configValues, setConfigValues] = useState({});
  const [loader, setLoader] = useState(false);
  const selectedlang = useContext(booleanContext);

  const getText = (attr) => {
    if (selectedlang) {
      return languages[selectedlang].optoutRequestPage[attr];
    }
    return languages.en.optoutRequestPage[attr];
  };

  // useEffect to get the configuration values
  useEffect(() => {
    let configOptions = {
      method: "GET",
      url: selectedlang
        ? `${process.env.REACT_APP_SRR_CONFIG_OPTOUT_ES}`
        : `${process.env.REACT_APP_SRR_CONFIG_OPTOUT}`,
    };
    const configData = getService(configOptions);
    configData
      .then((response) => {
        if (response.status === 200 && response.data) {
          const { requests, settingsId } = response.data.srrConfig;
          const actions = requests.reduce((actionsMap, request) => {
            actionsMap[request.requestType] = request;
            return actionsMap;
          }, {});
          setConfigValues({ actions, settings: settingsId, _id: settingsId });
        }
      })
      .catch((err) => {
        throw err;
      });
  }, [selectedlang]);

  // handling the submit request root Id here
  const handleSubmitRequestId = (value) => {
    setId(value);
  };

  // handling the acknowledgement activation here
  const handleAcknowledgement = (acknowledge) => {
    setShowAcknowledgement(acknowledge);
  };

  // handling the loader activation here
  const handleLoader = (value) => {
    setLoader(value);
  };
  return (
    <>
      {loader ? (
        <div className="">
          <Spinner animation="grow" variant="primary" className="spinner" />
        </div>
      ) : showAcknowledgement ? (
        <Acknowledgement id={id} request="optOut" getText={getText} />
      ) : (
        <>
          <div className="title">{getText("pageTitle")}</div>
          <div className="cali-description">
            <p>{getText("pageIntro")}</p>

            <p>
              <strong>{getText("impInfo")}:</strong> {getText("infoDesc")}
            </p>
            <ul>
              <li> {getText("infoText1")} </li>
              <li>{getText("infoText2")}</li>
              <li>
                {" "}
                {getText("review")}{" "}
                <a
                  href="https://www.aflac.com/about-aflac/privacy-policy.aspx"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  target="_blank"
                  rel="noreferrer noopener"
                  title="https://www.aflac.com/about-aflac/privacy-policy.aspx"
                >
                  {" "}
                  {getText("privacyPolicy")}{" "}
                </a>{" "}
                {getText("reviewText")}{" "}
              </li>
              {selectedlang ? (
                <li>
                  Si presenta esta solicitud en nombre de otra persona, debe
                  hacerlo por correo postal. Descargue{" "}
                  <a href="/instructions">aquí</a> las instrucciones de envío
                  por correo postal en virtud de la CCPA.
                </li>
              ) : (
                <li>
                  {getText("requestByMail")} <a href="/instructions">here</a>.
                </li>
              )}
            </ul>

            <OptOutForm
              getSubmitRequestId={handleSubmitRequestId}
              getAcknowledgement={handleAcknowledgement}
              configValues={configValues}
              enableLoader={handleLoader}
              getText={getText}
              selectedlang={selectedlang}
            />
          </div>
        </>
      )}
    </>
  );
}

export default OptOutRequest;
