import React, { useContext } from "react";
import "./HeaderNavbar.css";
import { booleanContext } from "App";

function YourRightsChoice(props) {
  const { getText } = props;
  const selectedlang = useContext(booleanContext);

  return (
    <>
      <div className={props.content}>
        <div
          className={selectedlang === "es" ? "nav-hover-sp-bg" : "nav-hover-bg"}
          onMouseOver={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        >
          <h5 className="nav-inner-title">{getText("rights")}</h5>
          <div className="nav-hover-list">
            <ul className="nav-item-list">
              <li className="nav-item">
                <a
                  href="https://www.aflac.com/about-aflac/affiliate-marketing-opt-out-form.aspx"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="nav-item-link"
                >
                  {getText("affMarketing")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  href={
                    selectedlang
                      ? `/${selectedlang}/exerciserights`
                      : `/exerciserights`
                  }
                  className="nav-item-link"
                >
                  {getText("exerciseRights")}
                </a>
              </li>
              <li className="empty-list">.</li>
            </ul>
          </div>

          <p
            className={
              selectedlang === "es"
                ? "privacy-center-label-sp"
                : "privacy-center-label"
            }
          >
            {getText("privacyCenter")}
          </p>
        </div>
      </div>
    </>
  );
}

export default YourRightsChoice;
