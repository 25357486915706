import React from "react";

function FirstFSDetails(props) {
  const { getText } = props;
  return (
    <div className="fs-details-item-border">
      <p>{getText("firstFSIntro")}</p>
      <ul className="fs-details-item-innerlist">
        <li className="fs-details-item-innerlist-item">
          {getText("listItem1")}
        </li>
        <li className="fs-details-item-innerlist-item">
          {" "}
          {getText("listItem2")}
        </li>
        <li className="fs-details-item-innerlist-item">
          {getText("listItem3")}
        </li>
        <li className="fs-details-item-innerlist-item">
          {getText("listItem4")}
        </li>
        <li className="fs-details-item-innerlist-item">
          {getText("listItem5")}
        </li>
        <li className="fs-details-item-innerlist-item">
          {getText("listItem6")}
        </li>
        <li className="fs-details-item-innerlist-item">
          {getText("listItem7")}
        </li>
      </ul>
    </div>
  );
}

export default FirstFSDetails;
