// import { constants } from "./constants";
// function to create the paylod with necessary fields based on request
export default function getPayload({
  selectedRequest,
  inputsData,
  configValues,
  isRequiredSpDp,
  selectedlang,
}) {
  for (let key in configValues.actions[selectedRequest]) {
    if (key === "fields") {
      const dataFields = configValues.actions[selectedRequest][key]
        .map((field) => {
          switch (field.label) {
            case "What is your current or former relationship to Aflac?":
              return {
                value: inputsData["relationshipToAflac"],
                label: field.label,
                _id: field._id,
              };
            case "What type of request would you like to make?":
              return {
                value: inputsData["requestType"],
                label: field.label,
                _id: field._id,
              };

            case "Select the company through which you have applied for or been issued coverage.":
              return {
                value: inputsData["status"],
                label: field.label,
                _id: field._id,
              };
            case "Are you the primary person who applied for coverage or the Spouse/Dependent?":
              return {
                value: inputsData["coverage"],
                label: field.label,
                _id: field._id,
              };
            case "Are you submitting this request for yourself?":
              return {
                value: inputsData["requestyourself"],
                label: field.label,
                _id: field._id,
              };
            case "First Name":
              return {
                value: inputsData["primaryFirstName"],
                label: field.label,
                _id: field._id,
              };
            case "Last Name":
              return {
                value: inputsData["primaryLastName"],
                label: field.label,
                _id: field._id,
              };
            case "Spouse/Dependent First Name":
              return {
                value: isRequiredSpDp ? inputsData["sdFirstName"] : "",
                label: field.label,
                _id: field._id,
              };
            case "Spouse/Dependent Last Name":
              return {
                value: isRequiredSpDp ? inputsData["sdLastName"] : "",
                label: field.label,
                _id: field._id,
              };
            case "Address Line 1":
              return {
                value: inputsData["address1"],
                label: field.label,
                _id: field._id,
              };
            case "Address Line 2":
              return {
                value: inputsData["address2"] || "",
                label: field.label,
                _id: field._id,
              };
            case "City":
              return {
                value: inputsData["city"],
                label: field.label,
                _id: field._id,
              };
            case "State":
              return {
                value: inputsData["state"],
                label: field.label,
                _id: field._id,
              };
            case "Postal Zip Code":
              return {
                value: inputsData["postalzipcode"],
                label: field.label,
                _id: field._id,
              };
            case "Country":
              return {
                value: inputsData["country"],
                label: field.label,
                _id: field._id,
              };
            case "Date of Birth":
              return {
                value: inputsData["dob"],
                label: field.label,
                _id: field._id,
              };
            case "Email":
              return {
                value: inputsData["email"],
                label: field.label,
                _id: field._id,
              };
            case "Authorized Agent First Name":
              return {
                value: inputsData["authorizedAgentFirstName"],
                label: field.label,
                _id: field._id,
              };
            case "Authorized Agent Last Name":
              return {
                value: inputsData["authorizedAgentLastName"],
                label: field.label,
                _id: field._id,
              };
            case "Authorized Agent Phone Number":
              return {
                value: inputsData["authorizedAgentPhone"],
                label: field.label,
                _id: field._id,
              };
            case "Authorized Agent Email Address":
              return {
                value: inputsData["authorizedAgentEmail"],
                label: field.label,
                _id: field._id,
              };
            case "Please specify what needs to be updated/corrected.":
              return {
                value: inputsData["updateRequestDetails"],
                label: field.label,
                _id: field._id,
              };
            case "E-Number":
              return {
                value: inputsData["eNumber"],
                label: field.label,
                _id: field._id,
              };
            case "Last 4 Digits of SSN":
              return {
                value: inputsData["ssnNumber"],
                label: field.label,
                _id: field._id,
              };
            case "Writing Number":
              return {
                value: inputsData["writingNumber"],
                label: field.label,
                _id: field._id,
              };
            case "Last 4 Digits of SSN / TIN":
              return {
                value: inputsData["ssntinNumber"],
                label: field.label,
                _id: field._id,
              };
            case "V-Number":
              return {
                value: inputsData["vNumber"],
                label: field.label,
                _id: field._id,
              };
            default:
              console.warn(`unknown field ${field.label}`);
          }
          return null;
        })
        .filter((field) => field);

      const primaryEmail = inputsData["email"] ? inputsData["email"] : "";

      const dataInputs = {
        data: {
          providedFields: dataFields,
          primaryEmail,
          providedRequests: [selectedRequest],
          locale: selectedlang ? selectedlang : "en",
        },
        meta: {
          id: `${configValues.settings}`,
          pageDataId: `${configValues._id}`,
          type: "dsar",
        },
      };
      return dataInputs;
    }
  }
}
