import React from "react";
import { Col, Row } from "react-bootstrap";

function FourthFSDetails(props) {
  const { getText } = props;
  return (
    <div className="fs-item-description fs-details-item-border">
      <p className="fs-fourthitem-description">{getText("fourthFSIntro")}</p>
      <div>
        <Row lg="2" md="2" sm="3">
          <Col>
            <p className="fs-fourthitem-abb">{getText("FTC")}: </p>
          </Col>
          <Col>
            <a
              href="https://www.consumer.ftc.gov/topics/privacy-identity-online-security"
              className="fs-fourthitem-link"
              target="_blank"
              rel="noreferrer noopener"
            >
              {getText("PIS")}
            </a>
          </Col>
        </Row>
        <Row lg="2" md="2" sm="3">
          <Col>
            <p className="fs-fourthitem-abb">{getText("FTC")}:</p>
          </Col>
          <Col>
            <a
              href="https://www.consumer.ftc.gov/features/feature-0038-onguardonline"
              className="fs-fourthitem-link"
              target="_blank"
              rel="noreferrer noopener"
            >
              {getText("safeSecure")}
            </a>
          </Col>
        </Row>
        <Row lg="2" md="2" sm="3">
          <Col>
            <p className="fs-fourthitem-abb">{getText("NCSA")}:</p>
          </Col>
          <Col>
            <a
              href="https://staysafeonline.org/stay-safe-online/"
              className="fs-fourthitem-link"
              target="_blank"
              rel="noreferrer noopener"
            >
              {" "}
              {getText("staySafe")}
            </a>
          </Col>
        </Row>
        <Row lg="2" md="2" sm="3">
          <Col>
            <p className="fs-fourthitem-abb">{getText("FBI")}:</p>
          </Col>
          <Col>
            <a
              href="https://www.fbi.gov/investigate/cyber"
              className="fs-fourthitem-link"
              target="_blank"
              rel="noreferrer noopener"
            >
              {" "}
              {getText("cyberCrime")}
            </a>
          </Col>
        </Row>
        <Row lg="2" md="2" sm="3">
          <Col>
            <p className="fs-fourthitem-abb">{getText("CTIA")}:</p>
          </Col>
          <Col>
            <a
              href="https://www.ctia.org/consumer-resources/protecting-your-data"
              className="fs-fourthitem-link"
              target="_blank"
              rel="noreferrer noopener"
            >
              {" "}
              {getText("protectData")}
            </a>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default FourthFSDetails;
