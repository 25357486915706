import React from "react";

function ThirdFSDetails(props) {
  const { getText } = props;
  return (
    <div className="fs-item-description fs-thirditem-description fs-details-item-border">
      {getText("thirdFSIntro")}{" "}
      <a
        href="https://secure.ethicspoint.com/domain/media/en/gui/54973/index.html"
        target="_blank"
        rel="noreferrer noopener"
        data-toggle="tooltip"
        data-placement="bottom"
        title="https://secure.ethicspoint.com/domain/media/en/gui/54973/index.html"
      >
        {getText("reportingFraud")}
      </a>{" "}
      {getText("thirdFSMoreIntro")}
    </div>
  );
}

export default ThirdFSDetails;
