import React from "react";

function PhishingDetails(props) {
  const { getText } = props;
  return (
    <div className="fs-second-details-item">
      <p>{getText("phishingIntro")}</p>
      <p>{getText("themesText")}: </p>
      <ul className="fs-details-item-innerlist">
        <li className="fs-details-item-innerlist-item">
          {getText("phishingText1")}
        </li>
        <li className="fs-details-item-innerlist-item">
          {getText("phishingText2")}
        </li>
        <li className="fs-details-item-innerlist-item">
          {getText("phishingText3")}
        </li>
        <li className="fs-details-item-innerlist-item">
          {getText("phishingText4")}
        </li>
        <li className="fs-details-item-innerlist-item">
          {getText("phishingText5")}
        </li>
      </ul>
      <br />
      <p>{getText("protectYourself")}: </p>
      <ul>
        <li className="fs-details-item-innerlist-item">
          {getText("phishingText6")}
        </li>
        <li className="fs-details-item-innerlist-item">
          {getText("phishingText7")}
        </li>
        <li className="fs-details-item-innerlist-item">
          {getText("phishingText8")}
        </li>
        <li className="fs-details-item-innerlist-item">
          {getText("phishingText9")}
        </li>
      </ul>
    </div>
  );
}

export default PhishingDetails;
