import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { booleanContext } from "App";

function OurPrivacyPolicies(props) {
  const { getText } = props;
  const selectedlang = useContext(booleanContext);
  return (
    <>
      <div className={props.content}>
        <div
          className={selectedlang === "es" ? "nav-hover-sp-bg" : "nav-hover-bg"}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        >
          <h5 className="nav-inner-title">{getText("policies")}</h5>
          <div className="nav-hover-list">
            <Row>
              <Col>
                <ul className="nav-item-list">
                  <li className="nav-item">
                    <a
                      href="https://www.aflac.com/about-aflac/privacy-policy.aspx"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="nav-item-link"
                    >
                      {getText("privacyPolicy")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://www.aflac.com/about-aflac/privacy-notices.aspx"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="nav-item-link"
                    >
                      {getText("privacyNotice")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://www.aflac.com/about-aflac/privacy-notifications.aspx"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="nav-item-link"
                    >
                      {getText("privacyNotif")}
                    </a>
                  </li>
                </ul>
              </Col>
              <Col className="second-list">
                <ul className="nav-item-list">
                  <li className="nav-item">
                    <a
                      href="https://www.aflac.com/about-aflac/careers-privacy-agreement.aspx"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="nav-item-link"
                    >
                      {getText("careerAgreement")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://www.aflac.com/about-aflac/terms-of-use.aspx"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="nav-item-link"
                    >
                      {getText("terms")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://www.aflac.com/about-aflac/cookies-and-tracking-technology-policy.aspx"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="nav-item-link"
                    >
                      {getText("cookies")}
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
          <p
            className={
              selectedlang === "es"
                ? "privacy-center-label-sp"
                : "privacy-center-label"
            }
          >
            {getText("privacyCenter")}
          </p>
        </div>
      </div>
    </>
  );
}

export default OurPrivacyPolicies;
