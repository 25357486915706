import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import PhishingDetails from "./SecondFSDetails/PhishingDetails";
import VishingDetails from "./SecondFSDetails/VishingDetails";
import SmishingDetails from "./SecondFSDetails/SmishingDetails";

const initialState = {
  phising: true,
  vishing: false,
  smishing: false,
};
function SecondFSDetails(props) {
  const { getText } = props;
  const [hovered, setHovered] = useState(initialState);
  return (
    <div className="fs-item-description fs-seconditem-description fs-details-item-border">
      <p>{getText("secondFSIntro")}</p>
      <p>{getText("secondFSMoreIntro")} </p>
      <div>
        <Row className="fs-seconddetails-innerlist">
          <Col
            className={
              hovered.phising
                ? "second-item-active fs-seconddetails-innerlist-item"
                : " fs-seconddetails-innerlist-item second-item-inactive"
            }
          >
            <p onClick={() => setHovered({ ...initialState, phising: true })}>
              {getText("phishing")}
            </p>
          </Col>
          <Col
            className={
              hovered.vishing
                ? "second-item-active fs-seconddetails-innerlist-item"
                : " fs-seconddetails-innerlist-item second-item-inactive"
            }
          >
            <p
              onClick={() =>
                setHovered({ ...initialState, vishing: true, phising: false })
              }
            >
              {getText("vishing")}
            </p>
          </Col>
          <Col
            className={
              hovered.smishing
                ? "second-item-active fs-seconddetails-innerlist-item"
                : " fs-seconddetails-innerlist-item second-item-inactive"
            }
            onClick={() =>
              setHovered({ ...initialState, smishing: true, phising: false })
            }
          >
            {getText("smishing")}
          </Col>
        </Row>

        {hovered.phising && <PhishingDetails getText={getText} />}
        {hovered.vishing && <VishingDetails getText={getText} />}
        {hovered.smishing && <SmishingDetails getText={getText} />}
        <br />
        <div>
          <h4 className="social-ea-recovery-info">
            {getText("recoverAttack")}
          </h4>
          <p className="social-ea-recovery-description">
            {getText("recoverSummary")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default SecondFSDetails;
