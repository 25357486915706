const textValidation = {
  required: "This field is required",
  minLength: {
    value: 2,
    message: "Minimum Length should be 2",
  },
  maxLength: {
    value: 20,
    message: "Maximum Length should be 20",
  },
  pattern: {
    value: /^[A-Za-z ]+$/i,
    message: "Please enter alphabets only",
  },
};

const textValidationES = {
  required: "Este campo es obligatorio",
  minLength: {
    value: 2,
    message: "La longitud mínima debe ser 2",
  },
  maxLength: {
    value: 20,
    message: "La longitud máxima debe ser 20",
  },
  pattern: {
    value: /^[A-Za-z ]+$/i,
    message: "Por favor ingrese solo alfabetos",
  },
};

const phoneValidation = {
  required: "This field is required",
  maxLength: {
    value: 10,
    message: "Please enter valid 10 digit number only",
  },
  pattern: {
    value: /^[0-9]+$/i,
    message: "Please enter number only",
  },
};

const phoneValidationES = {
  required: "Este campo es obligatorio",
  maxLength: {
    value: 10,
    message: "Por favor ingrese solo un número válido de 10 dígitos",
  },
  pattern: {
    value: /^[0-9]+$/i,
    message: "Por favor ingrese solo el número",
  },
};

const emailValidation = {
  required: "This field is required",
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: "Please enter valid email",
  },
};

const emailValidationES = {
  required: "Este campo es obligatorio",
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: "Por favor ingrese un email valido",
  },
};

const zipcodeValidation = {
  required: "This field is required",
  pattern: {
    value: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
    message: "Please enter valid 5 digit Zip Code only",
  },
};

const zipcodeValidationES = {
  required: "Este campo es obligatorio",
  pattern: {
    value: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
    message: "Por favor ingrese solo un código postal válido de 5 dígitos",
  },
};

export {
  textValidation,
  phoneValidation,
  emailValidation,
  zipcodeValidation,
  textValidationES,
  phoneValidationES,
  emailValidationES,
  zipcodeValidationES,
};
