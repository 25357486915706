import React, { useContext } from "react";
import StarterPage from "shared/components/starterpage/StarterPage";
import { languages } from "shared/languages/languages";
import { booleanContext } from "App";

function SubmitRequestStart() {
  const digitalText = true;
  const selectedlang = useContext(booleanContext);

  const getText = (attr) => {
    if (selectedlang) {
      return languages[selectedlang].submitRequestStart[attr];
    }
    return languages.en.submitRequestStart[attr];
  };

  return (
    <div>
      <StarterPage
        introText={
          <>
            <p>{getText("introText")}</p>
          </>
        }
        digitalText={digitalText}
      />
    </div>
  );
}

export default SubmitRequestStart;
