import React, { useContext } from "react";
import OurPolicyCards from "./OurPolicyCards/OurPolicyCards";
import "./OuPrivacyPolicies.css";
import Our_Privacy_Policy from "shared/images/1_Our_Privacy_Policy.webp";
import HeroBanner from "shared/HeroBanner/HeroBanner";
import { languages } from "shared/languages/languages";
import { booleanContext } from "App";

function OuPrivacyPolicies() {
  const selectedlang = useContext(booleanContext);

  const getText = (attr) => {
    if (selectedlang) {
      return languages[selectedlang].privacyPolicies[attr];
    }
    return languages.en.privacyPolicies[attr];
  };
  return (
    <div>
      <HeroBanner title={getText("bannerTitle")} img={Our_Privacy_Policy} />
      <div className="page-title">{getText("pageTitle")}</div>
      <p className="page-description">{getText("pageDescription")}</p>
      <OurPolicyCards getText={getText} />
    </div>
  );
}

export default OuPrivacyPolicies;
