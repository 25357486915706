import React, { useState } from "react";
import Switch from "react-switch";
import "./ContrastToggler.scss";

const ContrastToggler = ({ getText }) => {
  const [checked, setChecked] = useState(
    localStorage.getItem("contrastmode") === "yes" ? true : false
  );
  const handleChange = (selected) => {
    let rootEl = document.querySelector("#root");
    if (rootEl) {
      rootEl.className = selected ? "contrast-mode" : "non-contrast-mode";
    }
    localStorage.setItem("contrastmode", selected ? "yes" : "no");
    setChecked(selected);
  };

  return (
    <div className="toggle-switch">
      <label className="toggle-label">{getText("highContrastMode")}</label>
      <Switch
        className={"contrast-switch"}
        onChange={handleChange}
        checked={checked}
        checkedIcon={false}
        uncheckedIcon={false}
        aria-label="contrast-mode-toggle"
      />
    </div>
  );
};

export default ContrastToggler;
