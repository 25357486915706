import React from "react";
import "./Acknowledgment.css";

function Acknowledgment(props) {
  const { id, request, getText } = props;
  return (
    <>
      <div className="page-bg">
        <div className="title">{getText("ackTitle")}</div>

        <div className="acknowledgment p-5">
          <h4 className="acknowledgment-title mb-4">{getText("cardTitle")}</h4>
          <span className="acknowledgment-description">
            <p>
              {request ? getText("cardText1OptOuts") : getText("cardText1")}{" "}
              <a
                href="mailto: no-reply@wirewheel.io"
                data-toggle="tooltip"
                data-placement="bottom"
                target="_blank"
                rel="noreferrer noopener"
                title="no-reply@wirewheel.io"
              >
                no-reply@wirewheel.io.
              </a>{" "}
              {getText("cardText2")}
            </p>

            <p>
              {getText("requestID")} {id}. {getText("save")}
            </p>

            {request ? "" : <p>{getText("cardText3")}</p>}
          </span>
        </div>
      </div>
    </>
  );
}

export default Acknowledgment;
