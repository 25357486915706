import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import HeroBanner from "shared/HeroBanner/HeroBanner";
import Keep_Info_Secure from "shared/images/5_Keep_Info_Secure.webp";
import FraudAndSecurityDetails from "./FraudAndSecurityDetails/FraudAndSecurityDetails/FraudAndSecurityDetails";
import "./FraudAndSecurity.css";
import { languages } from "shared/languages/languages";
import { booleanContext } from "App";

function FraudAndSecurity() {
  const selectedlang = useContext(booleanContext);

  const getText = (attr) => {
    if (selectedlang) {
      return languages[selectedlang].fraudSecurity[attr];
    }
    return languages.en.fraudSecurity[attr];
  };

  return (
    <div className="privacycenterzzz">
      <HeroBanner title={getText("bannerTitle")} img={Keep_Info_Secure} />
      <div className="page-title">{getText("pageTitle")}</div>
      <div className="fraud-security-reviews">
        <Row lg="3" md="3" sm="1" xs="1">
          <Col className="fs-review">
            <div>
              <blockquote className="fs-review-detail">
                {getText("quote1")}
              </blockquote>
              <p className="fs-reviewer-name">
                - {getText("reviewerName1")}, October 2020
              </p>
            </div>
          </Col>
          <Col className="fs-review">
            <div>
              <blockquote className="fs-review-detail">
                {getText("quote2")}
              </blockquote>
              <p className="fs-reviewer-name">
                - {getText("reviewerName2")}, March 2020
              </p>
            </div>
          </Col>
          <Col>
            <div>
              <blockquote className="fs-review-detail">
                {getText("quote3")}
              </blockquote>
              <p className="fs-reviewer-name">
                - {getText("reviewerName3")}, August 2020
              </p>
            </div>
          </Col>
        </Row>
      </div>
      <p className="page-description">{getText("pageDescription")}</p>
      <br />
      <FraudAndSecurityDetails getText={getText} />
    </div>
  );
}

export default FraudAndSecurity;
